// -------- Types --------

export type FiltersQueriesConfig<QueryName extends string = string> = {
  condition?: Condition; // Only for `advanced` type
  value: any;
  type: 'simple' | 'advanced';
  name: QueryName;
};

export type Condition =
  | 'greater-than-or-equal'
  | 'less-than-or-equal'
  | 'not-begin-with'
  | 'greater-than'
  | 'not-end-with'
  | 'not-contain'
  | 'begin-with'
  | 'not-empty'
  | 'not-equal'
  | 'less-than'
  | 'end-with'
  | 'contain'
  | 'equal'
  | 'empty';

export type FreeQueries = Record<string, string | number | boolean | Array<string | number | boolean>>;

export type PaginatedListQueries = { limit?: number; offset?: number };

export type SortQueries = { ordering?: string };

export type SearchQueries = { search?: string };

// ----------------

export const queryService = {
  createFiltersQueries<QueryName extends string = string>(
    queryNames: Partial<Record<QueryName, any>>,
    queriesConfig: FiltersQueriesConfig<QueryName>[],
    additionalConfig?: { advancedFiltersMode?: 'and' | 'or'; prefix?: string }
  ): Record<string, any> {
    // prettier-ignore
    const conditionMap: Record<Condition, string> = {
          'greater-than-or-equal': 'gte',
          'less-than-or-equal'   : 'lte',
          'not-begin-with'       : 'startswith!',
          'greater-than'         : 'gt',
          'not-end-with'         : 'endswith!',
          'not-contain'          : 'contains!',
          'begin-with'           : 'startswith',
          'not-empty'            : 'empty!',
          'not-equal'            : 'exact!',
          'less-than'            : 'lt',
          'end-with'             : 'endswith',
          contain                : 'contains',
          equal                  : 'exact',
          empty                  : 'empty',
        };

    // --------

    const queries: Record<string, any> = {};

    queriesConfig.forEach((config) => {
      if (!queryNames[config.name]) {
        return;
      }

      let key = config.type === 'advanced' ? 'adv_' : '';
      key += additionalConfig?.prefix || '';
      key += queryNames[config.name];

      let value = config.value;

      if (config.type === 'advanced' && config.condition) {
        key += '__';

        if (Array.isArray(config.value)) {
          key += 'in';

          if (config.condition === 'not-equal') {
            key += '!';
          }
        } else {
          key += conditionMap[config.condition];
        }

        if (config.condition === 'empty' || config.condition === 'not-empty') {
          value = '';
        }
      } else {
        if (Array.isArray(config.value)) {
          value = config.value.join(',');
        }
      }

      queries[key] = value;
    });

    if (additionalConfig) {
      if (additionalConfig.advancedFiltersMode) {
        queries.combine = additionalConfig.advancedFiltersMode;
      }
    }

    return queries;
  },

  // --------

  createPaginatedListQueries(page?: number, itemsPerPage: number = 20, fullLoad: boolean = false): PaginatedListQueries {
    if (!page) {
      return {};
    }

    if (fullLoad) {
      return {
        offset: 0,
        limit: Number(page) * itemsPerPage,
      };
    } else {
      return { offset: Number(page) * itemsPerPage - itemsPerPage, limit: itemsPerPage };
    }
  },

  // --------

  createSortQueries<Key extends string = string>(
    keysMap: Record<Key, string>,
    name?: Key,
    direction: 'desc' | 'asc' = 'desc',
    prefix: string = ''
  ): SortQueries {
    return name && keysMap[name] ? { ordering: (direction === 'asc' ? '-' : '') + prefix + keysMap[name] } : {};
  },
};
