import { BaseDO, GlossaryId, Number, Date } from '../base';

// ----------------

export namespace SmallWorksDO {
  // prettier-ignore
  export type $ = {
    swNumber: string;
    subcontractor: GlossaryId;
    description: string;
    startDatePlanned: Date;
    permits: string;
    startDateActual: Date;
    finalCompletionDate: Date;
    fssr: string;
    checkAsBuilt: GlossaryId;
    boqInitial: string;
    boqFinal: string;
    ber: string;
    bep: string;
    comments: string;
    coconEncoding: boolean;
    swOwner: string;
    commentAsBuilt: string;
    isArchived: boolean;
    id: number;
  };

  export type PaginatedList = BaseDO.PaginatedList<$>;
}
