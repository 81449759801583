import { TXDO } from '../entity/tx';

const alias = {
  leasedLineTransmissions: 'main.transmission.leasedLine',
  transmissionLeasedLine: 'main.transmission.details.nav.leasedLine',
  microwaveTransmissions: 'main.transmission.microwave',
  candidateTransmissions: 'main.transmission.candidate',
  reconstructionHistory: 'main.reconstruction-details.history',
  reconstructionDetails: 'main.reconstruction-details',
  smallWorksHistory: 'main.smallWorks-details.history',
  smallWorksDetails: 'main.smallWorks-details',
  blueMoonTransmission: 'main.transmission.blue-moon',
  archiveTransmissions: 'main.transmission.archive',
  fiberTransmissions: 'main.transmission.fiber',
  singleTransmission: 'main.transmission.single',
  allTransmissions: 'main.transmission.all',
  reconstruction: 'main.reconstruction',
  smallWorks: 'main.smallWorks',
  fiberDashboard: 'main.fiber-dashboard',
  b2bDashboard: 'main.b2b-dashboard',
  transmission: 'main.transmission',
  cartography: 'main.transmission.cartography',
  b2bDocument: 'main.b2b-details.document',
  b2bHistory: 'main.b2b-details.history',
  leasedLine: 'main.leasedLine',
  highlights: 'main.highlights',
  b2bDetails: 'main.b2b-details',
  dashboard: 'main.dashboard',
  microwave: 'main.microwave',
  b2bNote: 'main.b2b-details.note',
  fiber: 'main.fiber',
  todo: 'main.todo',
  b2b: 'main.b2b',

  txDetails: {
    microwaveSynopsis: `main.transmission.details.nav.microwaveSynopsis`,
    leasedLine: 'main.transmission.details.nav.leasedLine',
    microwave: 'main.transmission.details.nav.microwave',
    fiberRing: 'main.transmission.details.nav.fiber-ring',
    documents: 'main.transmission.details.nav.documents',
    blueMoon: 'main.transmission.details.nav.blue-moon',
    timeline: 'main.transmission.details.nav.timeline',
    fiber: 'main.transmission.details.nav.fiber',
    notes: 'main.transmission.details.nav.notes',
    root: 'main.transmission.details',
  },
};
export const getRouteAliasByCategory = (category: TXDO.Category) => {
  switch (category) {
    case 'fiber':
      return alias.txDetails.fiber;
    case 'leasedLine':
      return alias.txDetails.leasedLine;
    case 'microwave':
      return alias.txDetails.microwave;
    case 'blueMoon':
      return alias.txDetails.blueMoon;
  }
};
// --------

export const topLevelRoutes = [
  alias.dashboard,
  alias.allTransmissions,
  alias.fiberTransmissions,
  alias.leasedLineTransmissions,
  alias.microwaveTransmissions,
  alias.candidateTransmissions,
  alias.archiveTransmissions,
];

export default alias;
