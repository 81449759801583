import { TXBranch, txBranchInitialState } from './txBranch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';

// Entities

import { TXDO } from '../../../entity/tx';

// ----------------

export const txSlice = createSlice({
  name: `${reduxActionTypePrefix}/tx`,
  initialState: txBranchInitialState,

  // -------- Reducers --------

  reducers: {
    set: (state, action: PayloadAction<{ list?: TXDO.$[]; meta?: Partial<TXBranch['meta']> }>) => {
      if (action.payload.list) {
        state.list = action.payload.list;
      }

      if (action.payload.meta) {
        state.meta = { ...state.meta, ...action.payload.meta };
      }
    },

    // --------

    setItem: (state, action: PayloadAction<{ data: TXDO.$ }>) => {
      const item = { ...action.payload.data };

      item.leasedLineData = null;
      item.microwaveData = null;
      item.fiberData = null;
      item.blueMoonData = null;
      item.blueMoon = null;

      state.item = item;
    },

    // --------

    add: (state, action: PayloadAction<{ data: TXDO.$ }>) => {
      state.list.unshift(action.payload.data);
    },

    // --------

    update: (state, action: PayloadAction<{ data: TXDO.$ }>) => {
      state.list = state.list.map((item) => (item.id === action.payload.data.id ? action.payload.data : item));
    },

    // --------

    delete: (state, action: PayloadAction<{ id: number }>) => {
      state.list = state.list.filter((item) => item.id !== action.payload.id);
    },

    // --------

    setCandidateCount: (state, action: PayloadAction<{ count: number }>) => {
      state.meta.candidateCount = action.payload.count;
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, () => {
      return txBranchInitialState;
    });
  },
});
