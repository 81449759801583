import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fiberBranchInitialState } from './fiberBranch';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalActionCreators from '../../actionCreators';

// Entities

import { FiberDO } from '../../../entity/fiber';

// ----------------

export const fiberSlice = createSlice({
  name: `${reduxActionTypePrefix}/fiber`,
  initialState: fiberBranchInitialState,
  reducers: {
    setItem: (state, action: PayloadAction<{ data: FiberDO | null }>) => {
      state.item = action.payload.data;
    },
  },
  extraReducers: (b) => {
    b.addCase(globalActionCreators.setDefaultState, (state) => {
      return fiberBranchInitialState;
    });
  },
});

