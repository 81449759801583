import { Router } from '../types';
import * as Query from './query';
import alias from './alias';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

// Config

import { notificationsLimit } from '../config/app';

// Redux

import {
  txMicrowaveSynopsisSlice,
  reconstructionSlice,
  smallWorksSlice,
  notificationsSlice,
  cartographySlice,
  leasedLineSlice,
  microwaveSlice,
  blueMoonSlice,
  timelineSlice,
  documentSlice,
  txFilesSlice,
  txNotesSlice,
  historySlice,
  graphSlice,
  fiberSlice,
  noteSlice,
  RootState,
  enumSlice,
  b2bSlice,
  _txSlice
} from '../redux';

// Services

import { queryService } from '../services/queryService';
import mainStream from '../services/ws/mainStream';

// Entities

import { GlossaryFamilyCodes, GlossaryBmStatusCodes } from '../entity/glossary';
import { getGlossaryValueByCode } from '../entity/glossary/utils/getGlossaryValueByCode';
import { reconstructionMapper } from '../entity/reconstruction';
import { smallWorksMapper } from '../entity/smallWorks';
import { txAnalyzer, txMapper } from '../entity/tx';
import { leasedLineMapper } from '../entity/leasedLine';
import { microwaveMapper } from '../entity/microwave';
import { blueMoonMapper } from '../entity/blueMoon';
import { fiberMapper } from '../entity/fiber';
import { CoreCommon } from '../entity/core';
import { UserCommon } from '../entity/user';
import { b2bMapper } from '../entity/b2b';

// Rest

import { advancedFiltersMapper } from '../components/view/common/TableTools/components/AdvancedFilters';
import { getTableLocalStorage } from '../components/view/common/TableX/utils/getTableLocalStorage';
import { filtersMapper } from '../components/view/common/TableTools/components/Filters';

// Types

import { LoadType } from '../types';

// ----------------

dayjs.extend(utc);

const routes: Router.IRoute[] = [
  {
    // -------- auth --------

    name: 'auth',
    path: '/auth',
    view: 'AuthBranch',
    forwardTo: 'auth.login',
    branches: [
      ['ForgotPasswordBranch', () => import('../components/branches/ForgotPasswordBranch/ForgotPasswordBranch')],
      ['LoginBranch', () => import('../components/branches/LoginBranch/LoginBranch')],
      ['MfaBranch', () => import('../components/branches/MfaBranch/MfaBranch')],
      ['AuthBranch', () => import('../components/branches/AuthBranch/AuthBranch')]
    ],
    access: {
      auth: false
    },

    // --------

    children: [
      // -------- auth.login --------

      {
        name: 'login',
        path: '/login',
        view: 'LoginBranch'
      },

      // -------- auth.forgotPassword --------

      {
        name: 'forgotPassword',
        path: '/forgot-password',
        view: 'ForgotPasswordBranch'
      },

      // -------- auth.resetPassword --------

      {
        name: 'resetPassword',
        path: '/reset-password?token',
        view: 'ResetBranch',
        branches: [['ResetBranch', () => import('../components/branches/ResetBranch/ResetBranch')]],
        access: {
          reqQuery: ['token']
        }
      },

      // -------- auth.mfa--------

      {
        name: 'mfa',
        path: '/mfa',
        view: 'MfaBranch'
      }
    ]

  },


  {
    name: 'main',
    path: '/main',
    view: 'MainBranch',
    forwardTo: 'main.dashboard',
    branches: [['MainBranch', () => import('../components/branches/MainBranch/MainBranch')]],
    access: {
      auth: true
    },
    onEnter: (store) => {
      store.dispatch(
        notificationsSlice.actions.getList$Req({
          query: {
            is_call_to_action: true,
            offset: 0,
            limit: notificationsLimit
          },
          onSuccess: (data) => {
            store.dispatch(notificationsSlice.actions.setList(data, 'action'));
          }
        })
      );

      store.dispatch(
        notificationsSlice.actions.getList$Req({
          query: {
            is_general: true,
            offset: 0,
            limit: notificationsLimit
          },
          onSuccess: (data) => {
            store.dispatch(notificationsSlice.actions.setList(data, 'general'));
          }
        })
      );

      store.dispatch(
        cartographySlice.actions.getPendingCount$Req({
          onSuccess(pendingCount) {
            store.dispatch(cartographySlice.actions.setPendingCount({ count: pendingCount }));
          }
        })
      );

      store.dispatch(
        _txSlice.actions.getCandidateCount$Req({
          onSuccess(count) {
            store.dispatch(_txSlice.actions.setCandidateCount({ count }));
          }
        })
      );

      mainStream.start();
      mainStream.initMessages();
    },

    // --------

    children: [
      // -------- main.dashboard --------

      {
        name: 'dashboard',
        path: `/dashboard?${Query.dashboardQueryList.join('&')}`,
        view: 'DashboardBranch',
        branches: [['DashboardBranch', () => import('../components/branches/DashboardBranch/DashboardBranch')]],
        access: {
          auth: true,
          permissions: [UserCommon.Permission.VIEW_DASHBOARD]
        },
        onEnter: (store, route) => {
          const params: Query.DashboardQuery = route.params;

          if (params.date) {
            const startDate = dayjs(params.date).utc().startOf('month').toISOString();
            const endDate = dayjs(params.date).utc().endOf('month').toISOString();

            store.dispatch(
              graphSlice.actions.getSnapshot$Req({
                onSuccess: (data) => {
                  store.dispatch(graphSlice.actions.setDataKit(data));
                },
                query: { created_at__gt: startDate, created_at__lt: endDate }
              })
            );
          } else {
            store.dispatch(
              graphSlice.actions.getDashboardData$Req({
                onSuccess: (data) => {
                  store.dispatch(graphSlice.actions.setDataKit(data));
                }
              })
            );
          }
        },
        onChangeQuery: (store, route) => {
          const params: Query.DashboardQuery = route.params;

          if (params.date) {
            const startDate = dayjs(params.date).utc().startOf('month').toISOString();
            const endDate = dayjs(params.date).utc().endOf('month').toISOString();

            store.dispatch(
              graphSlice.actions.getSnapshot$Req({
                onSuccess: (data) => {
                  store.dispatch(graphSlice.actions.setDataKit(data));
                },
                query: { created_at__gt: startDate, created_at__lt: endDate }
              })
            );
          } else {
            store.dispatch(
              graphSlice.actions.getDashboardData$Req({
                onSuccess: (data) => {
                  store.dispatch(graphSlice.actions.setDataKit(data));
                }
              })
            );
          }
        }
      },

      // -------- main.transmission --------

      {
        name: 'transmission',
        path: '/tx',
        forwardTo: 'main.transmission.all',
        onEnter(store) {
          store.dispatch(enumSlice.actions.getEnums$Req({ entities: ['tx'] }));
        },
        children: [
          // -------- main.transmission.all --------

          {
            name: 'all',
            path: `/all?page`,
            view: 'AllTXList',
            defParams: { page: 1 },
            branches: [['AllTXList', () => import('../components/branches/AllTXList')]],
            access: {
              permissions: [UserCommon.Permission.VIEW_ALL_TX]
            },
            onEnterOrChangeQuery: (store, route) => {
              const state = store.getState() as RootState;

              const tableLocalStorage = getTableLocalStorage('all-tx');
              const tableStorage = state.user_n.data.uiStorage.entity.tx.table.base;

              let filtersQueries = {};

              if (tableStorage.filterType === 'simple') {
                filtersQueries = queryService.createFiltersQueries(
                  { ...txMapper.getDOKeysMap(), move: 'in_move', target: 'has_originals' },
                  filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {})
                );
              } else {
                filtersQueries = queryService.createFiltersQueries(
                  txMapper.getDOKeysMap(),
                  advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
                  { advancedFiltersMode: tableStorage.filters.values?.mode! }
                );
              }

              store.dispatch(
                _txSlice.actions.getPaginatedList$Req({
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, tableLocalStorage.rowsPerPage),
                    ...queryService.createSortQueries(txMapper.getDOKeysMap(), tableLocalStorage.sortByColumn, tableLocalStorage.sortDirection),
                    ...(tableStorage.search ? { search: tableStorage.search } : {}),
                    ...filtersQueries,
                    is_archived: !!tableStorage.isArchived,
                    is_deleted: false,
                    is_draft: 0
                  },
                  onSuccess(data) {
                    store.dispatch(_txSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            }
          },

          // -------- main.transmission.fiber --------

          {
            name: 'fiber',
            path: `/fiber?page`,
            view: 'FiberTXList',
            defParams: { page: 1 },
            branches: [['FiberTXList', () => import('../components/branches/FiberTXList')]],
            access: {
              permissions: [UserCommon.Permission.VIEW_FIBER]
            },
            onEnterOrChangeQuery: (store, route) => {
              const state = store.getState() as RootState;

              const familyGlossaryValues = getGlossaryValueByCode(
                [GlossaryFamilyCodes.fttn, GlossaryFamilyCodes.ftts],
                state.glossary.glossaries.family
              );

              const tableLocalStorage = getTableLocalStorage('fiber-tx');
              const tableStorage = state.user_n.data.uiStorage.entity.tx.table.fiber;

              let filtersQueries = {};

              if (tableStorage.filterType === 'simple') {
                filtersQueries = {
                  ...queryService.createFiltersQueries(
                    { ...txMapper.getDOKeysMap(), move: 'in_move', target: 'has_originals' },
                    filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {})
                  ),
                  ...queryService.createFiltersQueries(
                    fiberMapper.getDOKeysMap(),
                    filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {}),
                    { prefix: 'fiberdata__' }
                  )
                };
              } else {
                filtersQueries = {
                  ...queryService.createFiltersQueries(
                    txMapper.getDOKeysMap(),
                    advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
                    { advancedFiltersMode: tableStorage.filters.values?.mode! }
                  ),
                  ...queryService.createFiltersQueries(
                    fiberMapper.getDOKeysMap(),
                    advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
                    { advancedFiltersMode: tableStorage.filters.values?.mode!, prefix: 'fiberdata__' }
                  )
                };
              }

              store.dispatch(
                _txSlice.actions.getPaginatedList$Req({
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, tableLocalStorage.rowsPerPage),
                    ...queryService.createSortQueries(txMapper.getDOKeysMap(), tableLocalStorage.sortByColumn, tableLocalStorage.sortDirection),
                    ...queryService.createSortQueries(
                      fiberMapper.getDOKeysMap(),
                      tableLocalStorage.sortByColumn,
                      tableLocalStorage.sortDirection,
                      'fiberdata__'
                    ),
                    ...(tableStorage.search ? { search: tableStorage.search } : {}),
                    ...filtersQueries,
                    family_mp_projected: familyGlossaryValues.join(','),
                    is_archived: !!tableStorage.isArchived,
                    is_deleted: false,
                    is_draft: 0
                  },
                  onSuccess(data) {
                    store.dispatch(_txSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            }
          },

          // -------- main.transmission.leasedLine --------

          {
            name: 'leasedLine',
            path: `/leased-line?page`,
            view: 'LeasedLineTXList',
            defParams: { page: 1 },
            branches: [['LeasedLineTXList', () => import('../components/branches/LeasedLineTXList')]],
            access: {
              permissions: [UserCommon.Permission.VIEW_LEASED_LINE]
            },
            onEnter(store, route) {
              store.dispatch(enumSlice.actions.getEnums$Req({ entities: ['leasedLine'] }));
            },
            onEnterOrChangeQuery: (store, route) => {
              const state = store.getState() as RootState;

              //   const familyGlossaryValues = getGlossaryValueByCode([GlossaryFamilyCodes.leasedLine], state.glossary.glossaries.family);

              const tableLocalStorage = getTableLocalStorage('leasedLine-tx');
              const tableStorage = state.user_n.data.uiStorage.entity.tx.table.leasedLine;

              let filtersQueries = {};

              if (tableStorage.filterType === 'simple') {
                filtersQueries = {
                  ...queryService.createFiltersQueries(
                    { ...txMapper.getDOKeysMap(), move: 'in_move', target: 'has_originals' },
                    filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {})
                  ),
                  ...queryService.createFiltersQueries(
                    leasedLineMapper.getDTOKeysMap(),
                    filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {}),
                    { prefix: 'leased_line__' }
                  )
                };
              } else {
                filtersQueries = {
                  ...queryService.createFiltersQueries(
                    txMapper.getDOKeysMap(),
                    advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
                    { advancedFiltersMode: tableStorage.filters.values?.mode! }
                  ),
                  ...queryService.createFiltersQueries(
                    leasedLineMapper.getDTOKeysMap(),
                    advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
                    { advancedFiltersMode: tableStorage.filters.values?.mode!, prefix: 'leased_line__' }
                  )
                };
              }

              store.dispatch(
                _txSlice.actions.getPaginatedList$Req({
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, tableLocalStorage.rowsPerPage),
                    ...queryService.createSortQueries(txMapper.getDTOKeysMap(), tableLocalStorage.sortByColumn, tableLocalStorage.sortDirection),
                    ...queryService.createSortQueries(
                      leasedLineMapper.getDTOKeysMap(),
                      tableLocalStorage.sortByColumn,
                      tableLocalStorage.sortDirection,
                      'leased_line__'
                    ),
                    ...(tableStorage.search ? { search: tableStorage.search } : {}),
                    ...filtersQueries,
                    leased_line_mode: true,
                    is_archived: !!tableStorage.isArchived,
                    is_deleted: false,
                    is_draft: 0
                    // family: familyGlossaryValues.join(','),
                  },
                  onSuccess(data) {
                    store.dispatch(_txSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            }
          },

          // -------- main.transmission.microwave --------

          {
            name: 'microwave',
            path: `/microwave?page`,
            view: 'MicrowaveTXList',
            defParams: { page: 1 },
            branches: [['MicrowaveTXList', () => import('../components/branches/MicrowaveTXList')]],
            access: {
              permissions: [UserCommon.Permission.VIEW_MICROWAVE]
            },
            onEnterOrChangeQuery: (store, route) => {
              const state = store.getState() as RootState;

              const familyGlossaryValues = getGlossaryValueByCode([GlossaryFamilyCodes.microwave], state.glossary.glossaries.family);

              const tableLocalStorage = getTableLocalStorage('microwave-tx');
              const tableStorage = state.user_n.data.uiStorage.entity.tx.table.microwave;

              let filtersQueries = {};

              if (tableStorage.filterType === 'simple') {
                filtersQueries = {
                  ...queryService.createFiltersQueries(
                    { ...txMapper.getDOKeysMap(), move: 'in_move', target: 'has_originals' },
                    filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {})
                  ),
                  ...queryService.createFiltersQueries(
                    microwaveMapper.getDOKeysMap(),
                    filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {}),
                    { prefix: 'microwave_data__' }
                  )
                };
              } else {
                filtersQueries = {
                  ...queryService.createFiltersQueries(
                    txMapper.getDOKeysMap(),
                    advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
                    { advancedFiltersMode: tableStorage.filters.values?.mode! }
                  ),
                  ...queryService.createFiltersQueries(
                    microwaveMapper.getDOKeysMap(),
                    advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
                    { advancedFiltersMode: tableStorage.filters.values?.mode!, prefix: 'microwave_data__' }
                  )
                };
              }

              store.dispatch(
                _txSlice.actions.getPaginatedList$Req({
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, tableLocalStorage.rowsPerPage),
                    ...queryService.createSortQueries(txMapper.getDOKeysMap(), tableLocalStorage.sortByColumn, tableLocalStorage.sortDirection),
                    ...queryService.createSortQueries(
                      microwaveMapper.getDOKeysMap(),
                      tableLocalStorage.sortByColumn,
                      tableLocalStorage.sortDirection,
                      'microwave_data__'
                    ),
                    ...(tableStorage.search ? { search: tableStorage.search } : {}),
                    ...filtersQueries,
                    is_archived: !!tableStorage.isArchived,
                    is_deleted: false,
                    is_draft: 0,
                    family: familyGlossaryValues.join(',')
                  },
                  onSuccess(data) {
                    store.dispatch(_txSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            }
          },

          // -------- main.transmission.blue-moon --------

          {
            name: 'blue-moon',
            path: '/blue-moon?page',
            view: 'BlueMoonList',
            access: { permissions: [UserCommon.Permission.VIEW_BLUE_MOON] },
            branches: [['BlueMoonList', () => import('../components/branches/BlueMoonList')]],
            defParams: { page: 1 },
            onEnterOrChangeQuery: (store, route) => {
              const state = store.getState() as RootState;

              const bmStatusGlossaryValues = getGlossaryValueByCode([GlossaryBmStatusCodes.keep], state.glossary.glossaries.bmStatus);
              const tableLocalStorage = getTableLocalStorage('blue-moon-tx');
              const tableStorage = state.user_n.data.uiStorage.entity.tx.table.blueMoon;

              let filtersQueries = {};

              if (tableStorage.filterType === 'simple') {
                filtersQueries = {
                  ...queryService.createFiltersQueries(
                    { ...txMapper.getDOKeysMap(), move: 'in_move', target: 'has_originals' },
                    filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {})
                  ),
                  ...queryService.createFiltersQueries(
                    blueMoonMapper.getDOKeysMap(),
                    filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {}),
                    { prefix: 'blue_moon__' }
                  )
                };
              } else {
                filtersQueries = {
                  ...queryService.createFiltersQueries(
                    txMapper.getDOKeysMap(),
                    advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
                    { advancedFiltersMode: tableStorage.filters.values?.mode! }
                  ),
                  ...queryService.createFiltersQueries(
                    blueMoonMapper.getDOKeysMap(),
                    advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
                    { advancedFiltersMode: tableStorage.filters.values?.mode!, prefix: 'blue_moon__' }
                  )
                };
              }

              store.dispatch(
                _txSlice.actions.getPaginatedList$Req({
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, tableLocalStorage.rowsPerPage),
                    ...queryService.createSortQueries(txMapper.getDOKeysMap(), tableLocalStorage.sortByColumn, tableLocalStorage.sortDirection),
                    ...queryService.createSortQueries(
                      blueMoonMapper.getDOKeysMap(),
                      tableLocalStorage.sortByColumn,
                      tableLocalStorage.sortDirection,
                      'blue_moon__'
                    ),
                    ...(tableStorage.search ? { search: tableStorage.search } : {}),
                    ...filtersQueries,
                    is_archived: !!tableStorage.isArchived,
                    is_deleted: false,
                    bm_status: bmStatusGlossaryValues.join(','),
                    is_draft: 0
                  },
                  onSuccess(data) {
                    store.dispatch(_txSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            }
          },

          // -------- main.transmission.candidate --------

          {
            name: 'candidate',
            path: `/candidate?page`,
            view: 'CandidateTXList',
            defParams: { page: 1 },
            branches: [['CandidateTXList', () => import('../components/branches/CandidateTXList')]],
            access: {
              permissions: [UserCommon.Permission.VIEW_CANDIDATE]
            },
            onEnterOrChangeQuery: (store, route) => {
              const state = store.getState() as RootState;

              const tableLocalStorage = getTableLocalStorage('candidate-tx');
              const tableStorage = state.user_n.data.uiStorage.entity.tx.table.candidate;

              let filtersQueries = {};

              if (tableStorage.filterType === 'simple') {
                filtersQueries = queryService.createFiltersQueries(
                  { ...txMapper.getDOKeysMap() },
                  filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {})
                );
              } else {
                filtersQueries = queryService.createFiltersQueries(
                  txMapper.getDOKeysMap(),
                  advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
                  { advancedFiltersMode: tableStorage.filters.values?.mode! }
                );
              }

              store.dispatch(
                _txSlice.actions.getPaginatedList$Req({
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, tableLocalStorage.rowsPerPage),
                    ...queryService.createSortQueries(txMapper.getDOKeysMap(), tableLocalStorage.sortByColumn, tableLocalStorage.sortDirection),
                    ...(tableStorage.search ? { search: tableStorage.search } : {}),
                    ...filtersQueries,
                    is_archived: 0,
                    is_deleted: false,
                    is_draft: 1
                  },
                  onSuccess(data) {
                    store.dispatch(_txSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            }
          },

          // -------- main.transmission.cartography --------

          {
            name: 'cartography',
            path: '/cartography?page&search&status&type&period',
            defParams: { page: 1, status: 'pending', type: 'add,addService,remove,removeService,changeTechno' },
            branches: [['CartographyList', () => import('../components/branches/CartographyList')]],
            view: 'CartographyList',
            access: {
              permissions: [UserCommon.Permission.VIEW_CARTOGRAPHY]
            },
            onEnter(store, route) {
              const params = route.params;
              const period = params.period ? params.period.split('.') : null;

              store.dispatch(
                cartographySlice.actions.getChangePaginatedList$Req({
                  queries: {
                    ...queryService.createPaginatedListQueries(params.page, 40, true),
                    status: params.status.split(','),
                    search: params.search,
                    type: params.type.split(','),
                    ...(period
                      ? {
                        element__comparing__report_previous__period_end: period[0],
                        element__comparing__report_next__period_end: period[1]
                      }
                      : {})
                  },
                  onSuccess(data) {
                    store.dispatch(cartographySlice.actions.setChange({
                      list: data.list,
                      meta: { totalCount: data.count }
                    }));
                  }
                })
              );

              store.dispatch(
                cartographySlice.actions.getComparingPaginatedList$Req({
                  queries: {
                    ...queryService.createPaginatedListQueries(1, 100, true)
                  },
                  onSuccess(data) {
                    store.dispatch(cartographySlice.actions.setComparing({
                      list: data.list,
                      meta: { totalCount: data.count }
                    }));
                  }
                })
              );
            },
            onChangeQuery(store, route) {
              const params = route.params;
              const period = params.period ? params.period.split('.') : null;

              store.dispatch(
                cartographySlice.actions.getChangePaginatedList$Req({
                  ...(Number(params.page) > 1 && { requestActionTypeData: 2 }),
                  queries: {
                    ...queryService.createPaginatedListQueries(params.page, 40),
                    status: params.status.split(','),
                    search: params.search,
                    type: params.type.split(','),
                    ...(period
                      ? {
                        element__comparing__report_previous__period_end: period[0],
                        element__comparing__report_next__period_end: period[1]
                      }
                      : {})
                  },
                  onSuccess(data) {
                    if (Number(params.page) > 1) {
                      store.dispatch(cartographySlice.actions.addManyChange({
                        list: data.list,
                        meta: { totalCount: data.count }
                      }));
                    } else {
                      store.dispatch(cartographySlice.actions.setChange({
                        list: data.list,
                        meta: { totalCount: data.count }
                      }));
                    }
                  }
                })
              );
            }
          },

          // -------- main.transmission.details --------

          {
            name: 'details',
            path: '/:group<all|fiber|leased-line|microwave|archive|candidate|blue-moon>/:txId',
            view: 'TXDetails',
            forwardTo: 'main.transmission.details.nav.fiber',
            defParams: { group: 'all' },
            branches: [['TXDetails', () => import('../components/branches/TXDetails')]],
            access: {
              beforeEnter: (store, router, toState, fromState, done, next) => {
                store.dispatch(
                  _txSlice.actions.getItem$Req({
                    id: toState.params.txId,
                    onSuccess(data) {
                      // Dynamically set the initial category based on data
                      let initialCategory = 'all'; // default to all if no specific data is found
                      if (data.fiberData) initialCategory = 'fiber';
                      else if (data.leasedLineData) initialCategory = 'leased-line';
                      else if (data.microwaveData) initialCategory = 'microwave';
                      else if (data.blueMoon) initialCategory = 'blue-moon';

                      store.dispatch(_txSlice.actions.setItem({ data }));
                      next({ name: `main.transmission.details.nav.${initialCategory}` });
                    },
                    onError(err) {
                      done({ redirect: { name: alias.dashboard } });
                    }
                  })
                );
              }
            },

            children: [
              // -------- main.transmission.details.nav --------

              {
                name: 'nav',
                path: '/nav',
                view: 'TXDetailsNav',
                branches: [['TXDetailsNav', () => import('../components/branches/TXDetailsNav')]],

                children: [
                  // -------- main.transmission.details.nav.fiber --------

                  {
                    name: 'fiber',
                    path: '/fiber',
                    view: 'TransmissionFiberBranch',
                    branches: [['TransmissionFiberBranch', () => import('../components/branches/TransmissionFiberBranch/TransmissionFiberBranch')]],
                    access: {
                      permissions: [UserCommon.Permission.VIEW_FIBER_TAB],
                      test: (store, router, toState, fromState) => {
                        const reduxState: RootState = store?.getState();

                        if (
                          reduxState.user_n.data.isStaff &&
                          [
                            txAnalyzer.getCategory(reduxState._tx.item, 'family'),
                            txAnalyzer.getCategory(reduxState._tx.item, 'familyMpProjected'),
                            txAnalyzer.getCategory(reduxState._tx.item, 'familyTemp')
                          ].some((category) => category === 'fiber')
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                      forwardTo: [
                        alias.txDetails.leasedLine,
                        alias.txDetails.microwave,
                        alias.txDetails.blueMoon,
                        alias.txDetails.documents,
                        alias.txDetails.notes,
                        alias.txDetails.microwaveSynopsis,
                        alias.txDetails.timeline,
                        alias.txDetails.fiberRing
                      ]
                    }
                  },

                  // -------- main.transmission.details.nav.leasedLine --------

                  {
                    name: 'leasedLine',
                    path: '/leased-line',
                    view: 'TXLeasedLine',
                    branches: [['TXLeasedLine', () => import('../components/branches/TXLeasedLine')]],
                    access: {
                      permissions: [UserCommon.Permission.VIEW_LEASED_LINE_TAB],
                      test: (store) => {
                        const reduxState: RootState = store.getState();
                        const tXFamilyGroupByFamilyProp = txAnalyzer.getCategory(reduxState._tx.item, 'family');
                        const tXFamilyGroupByMpProjectedProp = txAnalyzer.getCategory(reduxState._tx.item, 'familyMpProjected');
                        const tXFamilyGroupByfamilyTempProp = txAnalyzer.getCategory(reduxState._tx.item, 'familyTemp');

                        if (
                          reduxState.user_n.data.isStaff &&
                          (tXFamilyGroupByFamilyProp === 'leasedLine' ||
                            tXFamilyGroupByMpProjectedProp === 'leasedLine' ||
                            tXFamilyGroupByfamilyTempProp === 'leasedLine')
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                      forwardTo: [
                        alias.txDetails.fiber,
                        alias.txDetails.microwave,
                        alias.txDetails.blueMoon,
                        alias.txDetails.documents,
                        alias.txDetails.notes,
                        alias.txDetails.microwaveSynopsis,
                        alias.txDetails.timeline,
                        alias.txDetails.fiberRing
                      ]
                    },
                    onEnter(store, route) {
                      store.dispatch(enumSlice.actions.getEnums$Req({ entities: ['leasedLine'] }));
                    }
                  },

                  // -------- main.transmission.details.nav.microwave --------

                  {
                    name: 'microwave',
                    path: '/microwave',
                    view: 'TransmissionMicrowaveBranch',
                    branches: [
                      ['TransmissionMicrowaveBranch', () => import('../components/branches/TransmissionMicrowaveBranch/TransmissionMicrowaveBranch')]
                    ],
                    access: {
                      permissions: [UserCommon.Permission.VIEW_MICROWAVE_TAB],
                      test: (store) => {
                        const reduxState: RootState = store.getState();
                        const tXFamilyGroupByFamilyProp = txAnalyzer.getCategory(reduxState._tx.item, 'family');
                        const tXFamilyGroupByMpProjectedProp = txAnalyzer.getCategory(reduxState._tx.item, 'familyMpProjected');
                        const tXFamilyGroupByfamilyTempProp = txAnalyzer.getCategory(reduxState._tx.item, 'familyTemp');

                        if (
                          reduxState.user_n.data.isStaff &&
                          (tXFamilyGroupByFamilyProp === 'microwave' ||
                            tXFamilyGroupByMpProjectedProp === 'microwave' ||
                            tXFamilyGroupByfamilyTempProp === 'microwave')
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                      forwardTo: [
                        alias.txDetails.fiber,
                        alias.txDetails.leasedLine,
                        alias.txDetails.blueMoon,
                        alias.txDetails.documents,
                        alias.txDetails.notes,
                        alias.txDetails.microwaveSynopsis,
                        alias.txDetails.timeline,
                        alias.txDetails.fiberRing
                      ]
                    }
                  },

                  // -------- main.transmission.details.nav.blue-moon --------

                  {
                    name: 'blue-moon',
                    path: '/blue-moon',
                    view: 'TXBlueMoon',
                    branches: [['TXBlueMoon', () => import('../components/branches/TXBlueMoon')]],
                    access: {
                      permissions: [UserCommon.Permission.VIEW_BLUE_MOON_TAB],
                      test: (store) => {
                        const reduxState: RootState = store.getState();
                        const bmStatusGlossaryValues = getGlossaryValueByCode([GlossaryBmStatusCodes.keep], reduxState.glossary.glossaries.bmStatus);

                        if (bmStatusGlossaryValues[0] === reduxState._tx.item.bmStatus) {
                          return true;
                        } else {
                          return false;
                        }
                      },
                      forwardTo: [
                        alias.txDetails.fiber,
                        alias.txDetails.leasedLine,
                        alias.txDetails.microwave,
                        alias.txDetails.documents,
                        alias.txDetails.notes,
                        alias.txDetails.microwaveSynopsis,
                        alias.txDetails.timeline,
                        alias.txDetails.fiberRing
                      ]
                    }
                  },

                  // -------- main.transmission.details.nav.documents --------

                  {
                    name: 'documents',
                    path: '/documents?page',
                    defParams: { page: 1 },
                    view: 'TXDocumentsBranch',
                    branches: [['TXDocumentsBranch', () => import('../components/branches/TXDocumentsBranch/TXDocumentsBranch')]],
                    onEnter: (store, route) => {
                      store.dispatch(txFilesSlice.actions.getTXFiles$Req({
                        page: route.params.page,
                        count: 18,
                        txId: route.params.txId
                      }));
                    },
                    onChangeQuery: (store, route) => {
                      store.dispatch(txFilesSlice.actions.getTXFiles$Req({
                        page: route.params.page,
                        count: 18,
                        txId: route.params.txId
                      }));
                    },
                    access: {
                      permissions: [UserCommon.Permission.VIEW_DOCUMENTS_TAB],
                      forwardTo: [
                        alias.txDetails.fiber,
                        alias.txDetails.leasedLine,
                        alias.txDetails.microwave,
                        alias.txDetails.blueMoon,
                        alias.txDetails.notes,
                        alias.txDetails.microwaveSynopsis,
                        alias.txDetails.timeline,
                        alias.txDetails.fiberRing
                      ]
                    }
                  },

                  // -------- main.tx.details.nav.notes --------

                  {
                    name: 'notes',
                    path: '/notes?page',
                    defParams: { page: 1 },
                    view: 'TXNotesBranch',
                    branches: [['TXNotesBranch', () => import('../components/branches/TXNotesBranch/TXNotesBranch')]],
                    onEnter: (store, route) => {
                      store.dispatch(txNotesSlice.actions.getTXNotes$Req({
                        page: route.params.page,
                        txId: route.params.txId
                      }));
                    },
                    onChangeQuery: (store, route) => {
                      store.dispatch(txNotesSlice.actions.getTXNotes$Req({
                        page: route.params.page,
                        txId: route.params.txId
                      }));
                    },
                    access: {
                      permissions: [UserCommon.Permission.VIEW_NOTES_TAB],
                      forwardTo: [
                        alias.txDetails.fiber,
                        alias.txDetails.leasedLine,
                        alias.txDetails.microwave,
                        alias.txDetails.blueMoon,
                        alias.txDetails.documents,
                        alias.txDetails.microwaveSynopsis,
                        alias.txDetails.timeline,
                        alias.txDetails.fiberRing
                      ]
                    }
                  },

                  // -------- main.tx.details.nav.microwaveSynopsis --------

                  {
                    name: 'microwaveSynopsis',
                    path: '/microwave-synopsis',
                    view: 'TXMicrowaveSynopsisBranch',
                    branches: [['TXMicrowaveSynopsisBranch', () => import('../components/branches/TXMicrowaveSynopsisBranch')]],
                    onEnter: (store, route) => {
                      store.dispatch(
                        txMicrowaveSynopsisSlice.actions.getList$Req({
                          txId: Number(route.params.txId),
                          onSuccess: (data) => {
                            store.dispatch(txMicrowaveSynopsisSlice.actions.set({ list: data }));
                          }
                        })
                      );
                    },
                    access: {
                      permissions: [UserCommon.Permission.VIEW_SYNOPTIC_TAB],
                      test: (store) => {
                        const reduxState: RootState = store.getState();
                        return txAnalyzer.getCategory(reduxState._tx.item, 'family') === 'microwave';
                      },
                      forwardTo: [
                        alias.txDetails.fiber,
                        alias.txDetails.leasedLine,
                        alias.txDetails.microwave,
                        alias.txDetails.blueMoon,
                        alias.txDetails.documents,
                        alias.txDetails.notes,
                        alias.txDetails.timeline,
                        alias.txDetails.fiberRing
                      ]
                    }
                  },

                  // -------- main.tx.details.nav.timeline --------

                  {
                    name: 'timeline',
                    path: '/timeline',
                    view: 'TXTimeline',
                    branches: [['TXTimeline', () => import('../components/branches/TXTimeline')]],
                    onEnter: (store, route) => {
                      store.dispatch(
                        timelineSlice.actions.getPaginatedList$Req({
                          txId: Number(route.params.txId),
                          onSuccess: (data) => {
                            store.dispatch(timelineSlice.actions.set({ list: data.list }));
                          }
                        })
                      );
                    },
                    access: {
                      permissions: [UserCommon.Permission.VIEW_TIMELINE_TAB],
                      forwardTo: [
                        alias.txDetails.fiber,
                        alias.txDetails.leasedLine,
                        alias.txDetails.microwave,
                        alias.txDetails.blueMoon,
                        alias.txDetails.documents,
                        alias.txDetails.notes,
                        alias.txDetails.microwaveSynopsis,
                        alias.txDetails.fiberRing
                      ]
                    }
                  },

                  // -------- main.tx.details.nav.fiber-ring --------

                  {
                    name: 'fiber-ring',
                    path: '/fiber-ring',
                    view: 'FiberRing',
                    branches: [['FiberRing', () => import('../components/branches/FiberRing')]],
                    access: {
                      permissions: [UserCommon.Permission.VIEW_FIBER_RING],
                      forwardTo: [
                        alias.txDetails.fiber,
                        alias.txDetails.leasedLine,
                        alias.txDetails.microwave,
                        alias.txDetails.blueMoon,
                        alias.txDetails.documents,
                        alias.txDetails.notes,
                        alias.txDetails.microwaveSynopsis,
                        alias.txDetails.timeline
                      ]
                    }
                  }
                ]
              }
            ]
          }
        ]
      },

      // -------- main.fiber-dashboard --------

      {
        name: 'fiber-dashboard',
        path: '/fiber/dashboard',
        view: 'FiberDashboardBranch',
        branches: [['FiberDashboardBranch', () => import('../components/branches/FiberDashboardBranch')]],
        access: { permissions: [UserCommon.Permission.VIEW_FIBER_DASHBOARD] }
      },

      {
        name: 'b2b-dashboard',
        path: '/b2b/dashboard',
        view: 'B2BDashboardBranch',
        branches: [['B2BDashboardBranch', () => import('../components/branches/B2BDashboardBranch')]],
        access: { permissions: [UserCommon.Permission.VIEW_B2B_DASHBOARD] }

      },
      // -------- main.b2b --------

      {
        name: 'b2b',
        path: '/b2b?page',
        view: 'B2B',
        defParams: { page: 1 },
        branches: [['B2B', () => import('../components/branches/B2B')]],
        access: {
          permissions: [UserCommon.Permission.VIEW_B2B]
        },
        onEnterOrChangeQuery(store, route) {
          const params = route.params;
          const state = store.getState() as RootState;

          const tableLocalStorage = getTableLocalStorage('b2b');
          const tableStorage = state.user_n.data.uiStorage.entity.b2b.table.base;

          let filtersQueries = {};

          if (tableStorage.filterType === 'simple') {
            filtersQueries = queryService.createFiltersQueries(
              b2bMapper.getKeysMap(),
              filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {})
            );
          } else {
            filtersQueries = queryService.createFiltersQueries(
              b2bMapper.getKeysMap(),
              advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
              { advancedFiltersMode: tableStorage.filters.values?.mode! }
            );
          }

          store.dispatch(
            b2bSlice.actions.getPaginatedList$Req({
              queries: {
                ...queryService.createPaginatedListQueries(params.page, tableLocalStorage.rowsPerPage),
                ...queryService.createSortQueries<string>(b2bMapper.getKeysMap(), tableLocalStorage.sortByColumn, tableLocalStorage.sortDirection),
                ...filtersQueries,
                is_archived: tableStorage.isArchived,
                search: tableStorage.search || undefined
              },
              onSuccess(data) {
                store.dispatch(b2bSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
              }
            })
          );
        }
      },

      // -------- main.b2b-details --------

      {
        name: 'b2b-details',
        path: '/b2b/:id',
        view: 'B2BDetails',
        forwardTo: 'main.b2b-details.note',
        branches: [['B2BDetails', () => import('../components/branches/B2BDetails')]],
        access: { permissions: [UserCommon.Permission.VIEW_B2B_DETAILS] },
        onEnter(store, route) {
          const state: RootState = store.getState();
          const data = state.b2b.list.find((item) => item.id === Number(route.params.id));

          if (data) {
            store.dispatch(b2bSlice.actions.setItem({ data }));
          } else {
            store.dispatch(
              b2bSlice.actions.getItem$Req({
                onSuccess(data) {
                  store.dispatch(b2bSlice.actions.setItem({ data }));
                },
                timeout: 1000,
                id: route.params.id
              })
            );
          }
        },

        // --------

        children: [
          // -------- main.b2b-details.note --------

          {
            name: 'note',
            path: '/note?page',
            view: 'NoteList',
            defParams: { page: 1 },
            branches: [['NoteList', () => import('../components/branches/NoteList')]],
            props: { canAddItem: UserCommon.Permission.B2B_ADD_NOTE, entityTypeModel: 'b2b' },
            onEnter(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === 'b2b');

              store.dispatch(
                noteSlice.actions.getPaginatedList$Req({
                  requestActionTypeData: LoadType.Base,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20, true),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    store.dispatch(noteSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            },
            onChangeQuery(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === 'b2b');

              store.dispatch(
                noteSlice.actions.getPaginatedList$Req({
                  requestActionTypeData: Number(route.params.page) === 1 ? LoadType.Base : LoadType.More,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    if (Number(route.params.page) === 1) {
                      store.dispatch(noteSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                    } else {
                      store.dispatch(noteSlice.actions.addMany({ list: data.list, meta: { totalCount: data.count } }));
                    }
                  }
                })
              );
            }
          },

          // -------- main.b2b-details.document --------

          {
            name: 'document',
            path: '/document?page',
            view: 'DocumentList',
            defParams: { page: 1 },
            branches: [['DocumentList', () => import('../components/branches/DocumentList')]],
            props: { canAddItem: UserCommon.Permission.B2B_ADD_DOCUMENT, entityTypeModel: 'b2b' },
            onEnter(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === 'b2b');

              store.dispatch(
                documentSlice.actions.getPaginatedList$Req({
                  requestActionTypeData: LoadType.Base,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20, true),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    store.dispatch(documentSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            },
            onChangeQuery(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === 'b2b');

              store.dispatch(
                documentSlice.actions.getPaginatedList$Req({
                  requestActionTypeData: Number(route.params.page) === 1 ? LoadType.Base : LoadType.More,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    if (Number(route.params.page) === 1) {
                      store.dispatch(documentSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                    } else {
                      store.dispatch(documentSlice.actions.addMany({
                        list: data.list,
                        meta: { totalCount: data.count }
                      }));
                    }
                  }
                })
              );
            }
          },

          // -------- main.b2b-details.history --------

          {
            name: 'history',
            path: '/history?page',
            view: 'HistoryListBranch',
            defParams: { page: 1 },
            branches: [['HistoryListBranch', () => import('../components/branches/HistoryListBranch')]],
            onEnter(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === CoreCommon.EntityTypeModel.b2b);

              store.dispatch(
                historySlice.actions.getPaginatedList$Req({
                  requestActionTypeData: LoadType.Base,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20, true),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    store.dispatch(historySlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            },
            onChangeQuery(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === CoreCommon.EntityTypeModel.b2b);

              store.dispatch(
                historySlice.actions.getPaginatedList$Req({
                  requestActionTypeData: Number(route.params.page) === 1 ? LoadType.Base : LoadType.More,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    if (Number(route.params.page) === 1) {
                      store.dispatch(historySlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                    } else {
                      store.dispatch(historySlice.actions.addMany({
                        list: data.list,
                        meta: { totalCount: data.count }
                      }));
                    }
                  }
                })
              );
            }
          }
        ]
      },

      // -------- main.reconstruction --------

      {
        name: 'reconstruction',
        path: '/reconstruction?page',
        view: 'ReconstructionList',
        defParams: { page: 1 },
        branches: [['ReconstructionList', () => import('../components/branches/ReconstructionList')]],
        access: {
          permissions: [UserCommon.Permission.VIEW_RECONSTRUCTION]
        },
        onEnterOrChangeQuery(store, route) {
          const params = route.params;
          const state = store.getState() as RootState;

          const tableLocalStorage = getTableLocalStorage('reconstruction');
          const tableStorage = state.user_n.data.uiStorage.entity.reconstruction.table.base;

          let filtersQueries = {};

          if (tableStorage.filterType === 'simple') {
            filtersQueries = queryService.createFiltersQueries(
              reconstructionMapper.getDOKeysMap(),
              filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {})
            );
          } else {
            filtersQueries = queryService.createFiltersQueries(
              reconstructionMapper.getDOKeysMap(),
              advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
              { advancedFiltersMode: tableStorage.filters.values?.mode! }
            );
          }

          store.dispatch(
            reconstructionSlice.actions.getPaginatedList$Req({
              queries: {
                ...queryService.createPaginatedListQueries(params.page, tableLocalStorage.rowsPerPage),
                ...filtersQueries,
                ...queryService.createSortQueries<string>(
                  reconstructionMapper.getDOKeysMap(),
                  tableLocalStorage.sortByColumn,
                  tableLocalStorage.sortDirection
                ),
                is_archived: tableStorage.isArchived,
                search: tableStorage.search || undefined
              },
              onSuccess(data) {
                store.dispatch(reconstructionSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
              }
            })
          );
        }
      },

      // -------- main.reconstruction-details --------

      {
        name: 'reconstruction-details',
        path: '/reconstruction/:id',
        view: 'ReconstructionDetails',
        forwardTo: 'main.reconstruction-details.note',
        branches: [['ReconstructionDetails', () => import('../components/branches/ReconstructionDetails')]],
        access: { permissions: [UserCommon.Permission.VIEW_RECONSTRUCTION_DETAILS] },
        onEnter(store, route) {
          const state: RootState = store.getState();
          const data = state.reconstruction.list.find((item) => item.id === Number(route.params.id));

          if (data) {
            store.dispatch(reconstructionSlice.actions.setItem({ data }));
          } else {
            store.dispatch(
              reconstructionSlice.actions.getItem$Req({
                onSuccess(data) {
                  store.dispatch(reconstructionSlice.actions.setItem({ data }));
                },
                timeout: 1000,
                id: route.params.id
              })
            );
          }
        },

        // --------

        children: [
          // -------- main.reconstruction-details.note --------

          {
            name: 'note',
            path: '/note?page',
            view: 'NoteList',
            defParams: { page: 1 },
            branches: [['NoteList', () => import('../components/branches/NoteList')]],
            props: { canAddItem: UserCommon.Permission.RECONSTRUCTION_ADD_NOTE, entityTypeModel: 'reconstruction' },
            onEnter(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === 'reconstruction');

              store.dispatch(
                noteSlice.actions.getPaginatedList$Req({
                  requestActionTypeData: LoadType.Base,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20, true),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    store.dispatch(noteSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            },
            onChangeQuery(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === 'reconstruction');

              store.dispatch(
                noteSlice.actions.getPaginatedList$Req({
                  requestActionTypeData: Number(route.params.page) === 1 ? LoadType.Base : LoadType.More,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    if (Number(route.params.page) === 1) {
                      store.dispatch(noteSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                    } else {
                      store.dispatch(noteSlice.actions.addMany({ list: data.list, meta: { totalCount: data.count } }));
                    }
                  }
                })
              );
            }
          },

          // -------- main.reconstruction-details.document --------

          {
            name: 'document',
            path: '/document?page',
            view: 'DocumentList',
            defParams: { page: 1 },
            branches: [['DocumentList', () => import('../components/branches/DocumentList')]],
            props: { canAddItem: UserCommon.Permission.RECONSTRUCTION_ADD_DOCUMENT, entityTypeModel: 'reconstruction' },
            onEnter(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === 'reconstruction');

              store.dispatch(
                documentSlice.actions.getPaginatedList$Req({
                  requestActionTypeData: LoadType.Base,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20, true),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    store.dispatch(documentSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            },
            onChangeQuery(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === 'reconstruction');

              store.dispatch(
                documentSlice.actions.getPaginatedList$Req({
                  requestActionTypeData: Number(route.params.page) === 1 ? LoadType.Base : LoadType.More,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    if (Number(route.params.page) === 1) {
                      store.dispatch(documentSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                    } else {
                      store.dispatch(documentSlice.actions.addMany({
                        list: data.list,
                        meta: { totalCount: data.count }
                      }));
                    }
                  }
                })
              );
            }
          },

          // -------- main.reconstruction-details.history --------

          {
            name: 'history',
            path: '/history?page',
            view: 'HistoryListBranch',
            defParams: { page: 1 },
            branches: [['HistoryListBranch', () => import('../components/branches/HistoryListBranch')]],
            onEnter(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === CoreCommon.EntityTypeModel.reconstruction);

              store.dispatch(
                historySlice.actions.getPaginatedList$Req({
                  requestActionTypeData: LoadType.Base,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20, true),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    store.dispatch(historySlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            },
            onChangeQuery(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === CoreCommon.EntityTypeModel.reconstruction);

              store.dispatch(
                historySlice.actions.getPaginatedList$Req({
                  requestActionTypeData: Number(route.params.page) === 1 ? LoadType.Base : LoadType.More,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    if (Number(route.params.page) === 1) {
                      store.dispatch(historySlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                    } else {
                      store.dispatch(historySlice.actions.addMany({
                        list: data.list,
                        meta: { totalCount: data.count }
                      }));
                    }
                  }
                })
              );
            }
          }

        ]
      },

      {
        name: 'smallWorks',
        path: '/smallWorks?page',
        view: 'SmallWorksList',
        defParams: { page: 1 },
        branches: [['SmallWorksList', () => import('../components/branches/SmallWorksList')]],
        access: {
          permissions: [UserCommon.Permission.VIEW_SMALLWORKS]
        },

        onEnterOrChangeQuery(store, route) {
          const params = route.params;
          const state = store.getState() as RootState;

          const tableLocalStorage = getTableLocalStorage('smallWorks');
          const tableStorage = state.user_n.data.uiStorage.entity.smallWorks.table.base;

          let filtersQueries = {};

          if (tableStorage.filterType === 'simple') {
            filtersQueries = queryService.createFiltersQueries(
              smallWorksMapper.getDOKeysMap(),
              filtersMapper.toFiltersQueriesConfig(tableStorage.simpleFilters.values || {})
            );
          } else {
            filtersQueries = queryService.createFiltersQueries(
              smallWorksMapper.getDOKeysMap(),
              advancedFiltersMapper.toFiltersQueriesConfig(tableStorage.filters.values || {}),
              { advancedFiltersMode: tableStorage.filters.values?.mode! }
            );
          }

          store.dispatch(
            smallWorksSlice.actions.getPaginatedList$Req({
              queries: {
                ...queryService.createPaginatedListQueries(params.page, tableLocalStorage.rowsPerPage),
                ...filtersQueries,
                ...queryService.createSortQueries<string>(
                  smallWorksMapper.getDOKeysMap(),
                  tableLocalStorage.sortByColumn,
                  tableLocalStorage.sortDirection
                ),
                is_archived: tableStorage.isArchived,
                search: tableStorage.search || undefined
              },
              onSuccess(data) {
                store.dispatch(smallWorksSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
              }
            })
          );
        }
      },
      // -------- main.smallWorks-details --------

      {
        name: 'smallWorks-details',
        path: '/smallWorks/:id',
        view: 'SmallWorksDetails',
        forwardTo: 'main.smallWorks-details.note',
        branches: [['SmallWorksDetails', () => import('../components/branches/SmallWorksDetails')]],
        access: { permissions: [UserCommon.Permission.VIEW_SMALLWORKS_DETAILS] },
        onEnter(store, route) {
          const state: RootState = store.getState();
          const data = state.smallWorks.list.find((item) => item.id === Number(route.params.id));

          if (data) {
            store.dispatch(smallWorksSlice.actions.setItem({ data }));
          } else {
            store.dispatch(
              smallWorksSlice.actions.getItem$Req({
                onSuccess(data) {
                  store.dispatch(smallWorksSlice.actions.setItem({ data }));
                },
                timeout: 1000,
                id: route.params.id
              })
            );
          }
        },

        // --------

        children: [
          // -------- main.smallWorks-details.note --------

          {
            name: 'note',
            path: '/note?page',
            view: 'NoteList',
            defParams: { page: 1 },
            branches: [['NoteList', () => import('../components/branches/NoteList')]],
            props: { canAddItem: UserCommon.Permission.SMALL_WORKS_ADD_NOTE, entityTypeModel: 'smallWorks' },
            onEnter(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === 'smallWorks');

              store.dispatch(
                noteSlice.actions.getPaginatedList$Req({
                  requestActionTypeData: LoadType.Base,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20, true),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    store.dispatch(noteSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            },
            onChangeQuery(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === 'smallWorks');

              store.dispatch(
                noteSlice.actions.getPaginatedList$Req({
                  requestActionTypeData: Number(route.params.page) === 1 ? LoadType.Base : LoadType.More,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    if (Number(route.params.page) === 1) {
                      store.dispatch(noteSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                    } else {
                      store.dispatch(noteSlice.actions.addMany({ list: data.list, meta: { totalCount: data.count } }));
                    }
                  }
                })
              );
            }
          },

          // -------- main.smallWorks-details.document --------

          {
            name: 'document',
            path: '/document?page',
            view: 'DocumentList',
            defParams: { page: 1 },
            branches: [['DocumentList', () => import('../components/branches/DocumentList')]],
            props: { canAddItem: UserCommon.Permission.SMALL_WORKS_ADD_DOCUMENT, entityTypeModel: 'smallWorks' },
            onEnter(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === 'smallWorks');

              store.dispatch(
                documentSlice.actions.getPaginatedList$Req({
                  requestActionTypeData: LoadType.Base,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20, true),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    store.dispatch(documentSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            },
            onChangeQuery(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === 'smallWorks');

              store.dispatch(
                documentSlice.actions.getPaginatedList$Req({
                  requestActionTypeData: Number(route.params.page) === 1 ? LoadType.Base : LoadType.More,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    if (Number(route.params.page) === 1) {
                      store.dispatch(documentSlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                    } else {
                      store.dispatch(documentSlice.actions.addMany({
                        list: data.list,
                        meta: { totalCount: data.count }
                      }));
                    }
                  }
                })
              );
            }
          },

          // -------- main.smallWorks-details.history --------

          {
            name: 'history',
            path: '/history?page',
            view: 'HistoryListBranch',
            defParams: { page: 1 },
            branches: [['HistoryListBranch', () => import('../components/branches/HistoryListBranch')]],
            onEnter(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === CoreCommon.EntityTypeModel.smallWorks);

              store.dispatch(
                historySlice.actions.getPaginatedList$Req({
                  requestActionTypeData: LoadType.Base,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20, true),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    store.dispatch(historySlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                  }
                })
              );
            },
            onChangeQuery(store, route) {
              const state: RootState = store.getState();
              const entityType = state.core.entityTypeList.find((item) => item.model === CoreCommon.EntityTypeModel.smallWorks);

              store.dispatch(
                historySlice.actions.getPaginatedList$Req({
                  requestActionTypeData: Number(route.params.page) === 1 ? LoadType.Base : LoadType.More,
                  queries: {
                    ...queryService.createPaginatedListQueries(route.params.page, 20),
                    content_type: entityType?.id,
                    object_id: route.params.id
                  },
                  onSuccess(data) {
                    if (Number(route.params.page) === 1) {
                      store.dispatch(historySlice.actions.set({ list: data.list, meta: { totalCount: data.count } }));
                    } else {
                      store.dispatch(historySlice.actions.addMany({
                        list: data.list,
                        meta: { totalCount: data.count }
                      }));
                    }
                  }
                })
              );
            }
          }
        ]

      }
    ]
  }
];

export default routes;
