import {
  TransmissionFiberDTOPartial,
  TransmissionDTOPartial,
  TransmissionFiberDTO,
  TransmissionDTOKeys,
  ITransmissionDTO,
  TransmissionFiberDTOKeys,
} from './DTO';
import {
  TransmissionFiberDOPartial,
  TransmissionFiberDOKeys,
  TransmissionDOPartial,
  TransmissionFiberDO,
  TransmissionDOKeys,
  ITransmissionDO,
} from './DO';
import { microwaveMapper, MicrowaveDO } from '../microwave';

// Config

import { globalObj } from '../../config/app';

// Redux

import { RootState } from '../../redux';

// Utils

import toNumberOrUndefined from '../../utils/toNumberOrUndefined/toNumberOrUndefined';
import getTXFamilyGroup from '../../utils/getTXFamilyGroup/getTXFamilyGroup';
import omitUndefined from '../../utils/omitUndefined/omitUndefined';

// ----------------

export default {
  toDO(data: ITransmissionDTO): ITransmissionDO {
    // prettier-ignore
    return {
      familyMpProjected: data.family_mp_projected,
      consoYearMwingz: data.conso_year_mwingz,
      microwaveData  : data.microwave_data ? microwaveMapper.toDO(data.microwave_data) : null,
      radioSiteType  : data.radio_site_type,
      clusterMwingz  : data.cluster_mwingz,
      currentTechno  : data.current_techno,
      doubleStream   : data.double_stream,
      targetTechno   : data.target_techno,
      familyGroup    : getTXFamilyGroup({ family: data.family, familyMpProjected: data.family_mp_projected } as ITransmissionDO ),
      radioStatus    : data.radio_status,
      leasedLine     : null, 
      isArchived     : data.is_archived,
      mwingzCode     : data.mwingz_code,
      tempTechno     : data.temp_techno,
      familyTemp     : data.family_temp,
      updateDate     : data.update_date,
      microwave      : data.microwave,
      fiberData      : data.fiber_data ? this.toFiberDO(data.fiber_data) : null,
      longitude      : Number(data.longitude),
      originals      : data.originals,
      latitude       : Number(data.latitude),
      bmStatus       : data.bm_status,
      isDraft        : data.is_draft,
      address        : data.address,
      siteId         : data.site_id,
      region         : data.region,
      status         : data.status,
      target         : data.target,
      family         : data.family,
      city           : data.city,
      zip            : Number(data.zip),
      id             : data.id,

      candidate2g: data.candidate_2g,
      candidate3g: data.candidate_3g,
      candidate4g: data.candidate_4g,
      candidate5g: data.candidate_5g,

      site2g: data.site_2g,
      site3g: data.site_3g,
      site4g: data.site_4g,
      site5g: data.site_5g,
    };
  },

  toDTOPartial(data: TransmissionDOPartial): TransmissionDTOPartial {
    // prettier-ignore
    const DTOPartial: TransmissionDTOPartial = {
      conso_year_mwingz: data.consoYearMwingz,
      radio_site_type  : data.radioSiteType,
      cluster_mwingz   : data.clusterMwingz,
      current_techno   : data.currentTechno,
      double_stream    : data.doubleStream,
      target_techno    : data.targetTechno,
      radio_status     : data.radioStatus,
      is_archived      : data.isArchived,
      mwingz_code      : data.mwingzCode,
      temp_techno      : data.tempTechno,
      update_date      : data.updateDate,
      bm_status        : data.bmStatus,
      microwave        : data.microwave,
      longitude        : toNumberOrUndefined(data.longitude),
      is_draft         : data.isDraft,
      latitude         : toNumberOrUndefined(data.latitude),
      address          : data.address,
      site_id          : data.siteId,
      region           : data.region,
      status           : data.status,
      family           : data.family,
      target           : data.target,
      city             : data.city,
      zip              : toNumberOrUndefined(data.zip),
      id               : data.id,

      candidate_2g: data.candidate2g,
      candidate_3g: data.candidate3g,
      candidate_4g: data.candidate4g,
      candidate_5g: data.candidate5g,

      site_2g: data.site2g,
      site_3g: data.site3g,
      site_4g: data.site4g,
      site_5g: data.site5g,
    };

    return omitUndefined(DTOPartial);
  },

  DTOKeyToEquallyDOKey(
    DTOKey: keyof typeof TransmissionDTOKeys | keyof typeof TransmissionFiberDTOKeys
  ): keyof typeof TransmissionDOKeys | keyof typeof TransmissionFiberDOKeys {
    // prettier-ignore
    const equally: { [key: string]: keyof typeof TransmissionDOKeys | keyof typeof TransmissionFiberDOKeys } = {
      conso_year_mwingz: 'consoYearMwingz',
      radio_site_type  : 'radioSiteType',
      cluster_mwingz   : 'clusterMwingz',
      current_techno   : 'currentTechno',
      double_stream    : 'doubleStream',
      target_techno    : 'targetTechno',
      radio_status     : 'radioStatus',
      is_archived      : 'isArchived',
      update_date      : 'updateDate',
      temp_techno      : 'tempTechno',
      mwingz_code      : 'mwingzCode',
      bm_status        : 'bmStatus',
      microwave        : 'microwave',
      longitude        : 'longitude',
      is_draft         : 'isDraft',
      latitude         : 'latitude',
      address          : 'address',
      site_id          : 'siteId',
      region           : 'region',
      status           : 'status',
      family           : 'family',
      target           : 'target',
      city             : 'city',
      zip              : 'zip',
      id               : 'id',

      candidate_2g: 'candidate2g',
      candidate_3g: 'candidate3g',
      candidate_4g: 'candidate4g',
      candidate_5g: 'candidate5g',

      site_2g: 'site2g',
      site_3g: 'site3g',
      site_4g: 'site4g',
      site_5g: 'site5g',


      family_mp_projected: 'familyMpProjected',
      family_bm_projected: 'familyBmProjected',
      family_bm_legacy   : 'familyBmLegacy',
      family_temp        : 'familyTemp',

      // Fiber

      fiber_delivered_estimate : 'fiberDeliveredEstimated',
      ring_send_to_ericsson    : 'ringSentEricsson',
      fiber_works_finished     : 'fiberWorksFinish',
      public_permit_region     : 'publicPermitRegion',
      civil_works_finished     : 'civilWorksFinish',
      public_permit_city       : 'publicPermitCity',
      as_built_received        : 'builtReceived',
      fiber_works_start        : 'fiberWorksStart',
      delivery_forecast        : 'deliveryForecast',
      civil_works_start        : 'civilWorksStart',
      inserted_in_ring         : 'insertedRING',
      fiber_delivered          : 'fiberDelivered',
      splice_partner           : 'splicingPartner',
      subcontractor            : 'subcontractor',
      ring_atn_name            : 'ATNRingName',
      order_date               : 'orderDate',
      owner_auth               : 'ownerAuthorization',
      saf_file                 : 'SAF',
      comments                 : 'SSVComments',
      network                  : 'network',
      pm_obe                   : 'PMOBE',
      ring                     : 'ringName',
      // popa                  : 'popa',
      // popb                  : 'popb',
      fac                      : 'FAC',
      ssv                      : 'SSV',
      rfb                      : 'RFB',
    };

    return equally[DTOKey];
  },

  DOKeyToEquallyDTOKey(
    DOKey: keyof typeof TransmissionDOKeys | keyof typeof TransmissionFiberDOKeys | keyof typeof MicrowaveDO.MicrowaveDOKeys
  ): string {
    // prettier-ignore
    const equally: { [key: string]: string } = {
      consoYearMwingz: 'conso_year_mwingz',
      radioSiteType  : 'radio_site_type',
      clusterMwingz  : 'cluster_mwingz',
      currentTechno  : 'current_techno',
      doubleStream   : 'double_stream',
      targetTechno   : 'target_techno',
      radioStatus    : 'radio_status',
      isArchived     : 'is_archived',
      updateDate     : 'update_date',
      mwingzCode     : 'mwingz_code',
      tempTechno     : 'temp_techno',
      microwave      : 'microwave',
      longitude      : 'longitude',
      bmStatus       : 'bm_status',
      latitude       : 'latitude',
      isDraft        : 'is_draft',
      address        : 'address',
      siteId         : 'site_id',
      region         : 'region',
      status         : 'status',
      family         : 'family',
      target         : 'target',
      city           : 'city',
      zip            : 'zip',
      id             : 'id',

      candidate2g: 'candidate_2g',
      candidate3g: 'candidate_3g',
      candidate4g: 'candidate_4g',
      candidate5g: 'candidate_5g',

      site2g: 'site_2g',
      site3g: 'site_3g',
      site4g: 'site_4g',
      site5g: 'site_5g',

      // Fiber

      fiberDeliveredEstimated: 'fiberdata__fiber_delivered_estimate',
      ownerAuthorization     : 'fiberdata__owner_auth',
      publicPermitRegion     : 'fiberdata__public_permit_region',
      civilWorksFinish       : 'fiberdata__civil_works_finished',
      fiberWorksFinish       : 'fiberdata__fiber_works_finished',
      publicPermitCity       : 'fiberdata__public_permit_city',
      ringSentEricsson       : 'fiberdata__ring_send_to_ericsson',
      deliveryForecast       : 'fiberdata__delivery_forecast',
      fiberWorksStart        : 'fiberdata__fiber_works_start',
      civilWorksStart        : 'fiberdata__civil_works_start',
      splicingPartner        : 'fiberdata__splice_partner',
      fiberDelivered         : 'fiberdata__fiber_delivered',
      builtReceived          : 'fiberdata__as_built_received',
      subcontractor          : 'fiberdata__subcontractor',
      // cancellation           : '',
      insertedRING           : 'fiberdata__inserted_in_ring',
      fiberDesign            : 'fiberdata__fiber_design',
      builtSplice            : 'fiberdata__as_built_splice',
      SSVComments            : 'fiberdata__comments',
      ATNRingName            : 'fiberdata__ring_atn_name',
      orderDate              : 'fiberdata__order_date',
      ringName               : 'fiberdata__ring',
      network                : 'fiberdata__network',
      PMOBE                  : 'fiberdata__pm_obe',
      cocon                  : 'fiberdata__coccon',
      SAF                    : 'fiberdata__saf_file',
      RFB                    : 'fiberdata__rfb',
      SSV                    : 'fiberdata__ssv',
      FAC                    : 'fiberdata__fac',

      // Microwave

      physicalLocationsAggregated: 'microwave_data__mw_physical_locations_aggregated',
      maxEthUtilization:'microwave_data__max_eth_utilization',
      linkCapacity:'microwave_data__link_capacity',
      linkHops: 'microwave_data__mw_link_hops',
      bEndNode: 'microwave_data__b_end_node',
      aEndNode: 'microwave_data__a_end_node',
      gneCode: 'microwave_data__gne_code',
      linkId: 'microwave_data__link_id',
      length: 'microwave_data__length',
      band: 'microwave_data__band',
      xpic: 'microwave_data__xpic',

      // Lease line
    };

    return equally[DOKey];
  },

  getDisplayTitle(DOKey: keyof typeof TransmissionDOKeys): string {
    // prettier-ignore
    const titels = {
      [TransmissionDOKeys.consoYearMwingz]: 'Conso Year Mwingz',
      [TransmissionDOKeys.radioSiteType]  : 'Site Type',
      [TransmissionDOKeys.clusterMwingz]  : 'Cluster Mwingz',
      [TransmissionDOKeys.currentTechno]  : 'Current Techno',
      [TransmissionDOKeys.doubleStream]   : 'Double Stream',
      [TransmissionDOKeys.targetTechno]   : 'Target Techno',
      [TransmissionDOKeys.radioStatus]    : 'Radio Status',
      [TransmissionDOKeys.isArchived]     : 'Archived',
      [TransmissionDOKeys.microwave]      : 'MW HUB/POP',
      [TransmissionDOKeys.longitude]      : 'X Coord',
      [TransmissionDOKeys.latitude]       : 'Y Coord',
      [TransmissionDOKeys.bmStatus]       : 'BM Status',
      [TransmissionDOKeys.isDraft]        : 'Candidate',
      [TransmissionDOKeys.address]        : 'Address',
      [TransmissionDOKeys.siteId]         : 'USI',
      [TransmissionDOKeys.status]         : 'Status',
      [TransmissionDOKeys.family]         : 'Family',
      [TransmissionDOKeys.city]           : 'City',
      [TransmissionDOKeys.zip]            : "Zip",
    
      [TransmissionDOKeys.candidate2g]: '2G Candidate',
      [TransmissionDOKeys.candidate3g]: '3G Candidate',
      [TransmissionDOKeys.candidate4g]: '4G Candidate',
      [TransmissionDOKeys.candidate5g]: '5G Candidate',
    
      [TransmissionDOKeys.site2g]: '2G Code',
      [TransmissionDOKeys.site3g]: '3G Code',
      [TransmissionDOKeys.site4g]: '4G Code',
      [TransmissionDOKeys.site5g]: '5G Code',
      
      [TransmissionDOKeys.familyMpProjected]: 'Family MP projected',
      [TransmissionDOKeys.mwingzCode]: 'Mwingz code',
      [TransmissionDOKeys.tempTechno]: 'Temp techno', 
      [TransmissionDOKeys.familyTemp]: 'Family temp',
      [TransmissionDOKeys.target]: 'Target',

      familyBmProjected: 'Family BM projected',
      familyBmLegacy: 'Family BM legacy'
    };

    return titels[DOKey];
  },

  getDisplayValue(DOKey: keyof typeof TransmissionDOKeys, value: any): string {
    // prettier-ignore
    const rules: Partial<{ [key in keyof typeof TransmissionDOKeys]: { type: 'default' | 'glossary' | 'boolean' , glossaryName?: string} }> = {
      [TransmissionDOKeys.consoYearMwingz]: { type: 'default' },
      [TransmissionDOKeys.radioSiteType]  : { type: 'glossary', glossaryName: 'radiositetype' },
      [TransmissionDOKeys.clusterMwingz]  : { type: 'glossary', glossaryName: 'clustermwingz' },
      [TransmissionDOKeys.currentTechno]  : { type: 'glossary', glossaryName: 'techno' },
      [TransmissionDOKeys.doubleStream]   : { type: 'glossary', glossaryName: 'techno' },
      [TransmissionDOKeys.targetTechno]   : { type: 'glossary', glossaryName: 'techno' },
      [TransmissionDOKeys.radioStatus]    : { type: 'glossary', glossaryName: 'radiostatus' },
      [TransmissionDOKeys.isArchived]     : { type: 'boolean', },
      [TransmissionDOKeys.microwave]      : { type: 'glossary', glossaryName: 'microwave' },
      [TransmissionDOKeys.longitude]      : { type: 'default' },
      [TransmissionDOKeys.latitude]       : { type: 'default' },
      [TransmissionDOKeys.bmStatus]       : { type: 'glossary', glossaryName: 'bmstatus' },
      [TransmissionDOKeys.isDraft]        : { type: 'boolean' },
      [TransmissionDOKeys.address]        : { type: 'default' },
      [TransmissionDOKeys.siteId]         : { type: 'default' },
      [TransmissionDOKeys.status]         : { type: 'glossary', glossaryName: 'status' },
      [TransmissionDOKeys.family]         : { type: 'glossary', glossaryName: 'family' },
      [TransmissionDOKeys.city]           : { type: 'glossary', glossaryName: 'city' },
      [TransmissionDOKeys.zip]            : { type: 'default' },
    
      [TransmissionDOKeys.candidate2g]: { type: 'default' },
      [TransmissionDOKeys.candidate3g]: { type: 'default' },
      [TransmissionDOKeys.candidate4g]: { type: 'default' },
      [TransmissionDOKeys.candidate5g]: { type: 'default' },
    
      [TransmissionDOKeys.site2g]: { type: 'default' },
      [TransmissionDOKeys.site3g]: { type: 'default' },
      [TransmissionDOKeys.site4g]: { type: 'default' },
      [TransmissionDOKeys.site5g]: { type: 'default' },
    };

    const reduxState: RootState = globalObj.reduxStore?.getState();
    const rule = rules[DOKey];

    switch (rule?.type) {
      case 'glossary': {
        const glossary = reduxState._glossary.data[rule.glossaryName!].find((item) => item.value === value);
        return glossary ? glossary.label : 'Empty';
      }

      case 'boolean': {
        return value ? 'Yes' : 'No';
      }

      default: {
        return value ? String(value) : 'Empty';
      }
    }
  },

  // prettier-ignore
  toFiberDO(data: TransmissionFiberDTO): TransmissionFiberDO {
    return {
      fiberDeliveredEstimated: data.fiber_delivered_estimate,
      ownerAuthorization     : data.owner_auth,
      publicPermitRegion     : data.public_permit_region,
      civilWorksFinish       : data.civil_works_finished,
      fiberWorksFinish       : data.fiber_works_finished,
      publicPermitCity       : data.public_permit_city,
      ringSentEricsson       : data.ring_send_to_ericsson,
      deliveryForecast       : data.delivery_forecast,
      fiberWorksStart        : data.fiber_works_start,
      civilWorksStart        : data.civil_works_start,
      splicingPartner        : data.splice_partner,
      fiberDelivered         : data.fiber_delivered,
      builtReceived          : data.as_built_received,
      subcontractor          : data.subcontractor,
      transmission           : data.transmission,
      cancellation           : '',
      insertedRING           : data.inserted_in_ring,
      fiberDesign            : data.fiber_design,
      builtSplice            : data.as_built_splice,
      SSVComments            : data.comments,
      ATNRingName            : data.ring_atn_name,
      orderDate              : data.order_date,
      ringName               : data.ring,
      network                : data.network,
      PMOBE                  : data.pm_obe,
      cocon                  : data.coccon,
      SAF                    : data.saf_file,
      RFB                    : data.rfb,
      SSV                    : data.ssv,
      FAC                    : data.fac,
    };
  },

  // prettier-ignore
  toFiberDTOPartial(data: TransmissionFiberDOPartial): TransmissionFiberDTOPartial {
    const DTOPartial = {
      fiber_delivered_estimate: data.fiberDeliveredEstimated,
      ring_send_to_ericsson   : data.ringSentEricsson,
      public_permit_region    : data.publicPermitRegion,
      civil_works_finished    : data.civilWorksFinish,
      fiber_works_finished    : data.fiberWorksFinish,
      public_permit_city      : data.publicPermitCity,
      delivery_forecast       : data.deliveryForecast,
      civil_works_start       : data.civilWorksStart,
      as_built_received       : data.builtReceived,
      fiber_delivered         : data.fiberDelivered,
      splice_partner          : data.splicingPartner,
      subcontractor           : data.subcontractor,
      fiber_works_start       : data.fiberWorksStart,
      // cancellation         : '',
      inserted_in_ring        : data.insertedRING,
      fiber_design            : data.fiberDesign,
      as_built_splice         : data.builtSplice,
      ring_atn_name           : data.ATNRingName,
      transmission            : data.transmission,
      owner_auth              : data.ownerAuthorization,
      order_date              : data.orderDate,
      comments                : data.SSVComments,
      saf_file                : data.SAF,
      network                 : data.network,
      pm_obe                  : data.PMOBE,
      ring                    : data.ringName,
      coccon                  : data.cocon,
      rfb                     : data.RFB,
      ssv                     : data.SSV,
      fac                     : data.FAC,
    };

    return omitUndefined(DTOPartial)
  },
};
