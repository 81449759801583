import initialState, { ITransmissionBranch } from './branch';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Action creators

import globalAC from '../../actionCreators';
import AC from './actionCreators';

// Entity

import { ITransmissionDO, TransmissionDOPartial } from '../../../entity/transmission/DO';

// Saga

import transmissionSaga from './saga';

// ----------------

const transmissionSlice = createSlice({
  name: `${reduxActionTypePrefix}/transmission`,
  initialState,

  // -------- Reducers --------

  reducers: {
    setData: {
      prepare: (data: ITransmissionDO) => ({
        payload: {
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ data: ITransmissionDO }>) => {
        state.data = action.payload.data;
      },


    },

    updateData: {
      prepare: (data: TransmissionDOPartial) => ({
        payload: {
          data,
        },
      }),

      reducer: (state, action: PayloadAction<{ data: TransmissionDOPartial }>) => {
        state.data = { ...state.data, ...action.payload.data };
      },
    },

    // --------

    setList: {
      prepare: (list: ITransmissionDO[], totalPages: number, totalItems: number) => ({
        payload: {
          totalItems,
          totalPages,
          list,
        },
      }),

      reducer: (state, action: PayloadAction<{ list: ITransmissionDO[]; totalPages: number; totalItems: number }>) => {
        state.totalPages = action.payload.totalPages;
        state.totalItems = action.payload.totalItems;
        state.list = action.payload.list;
      },
    },

    // --------

    updateListItem: {
      prepare: (item: ITransmissionDO) => ({
        payload: {
          item,
        },
      }),

      reducer: (state, action: PayloadAction<{ item: ITransmissionDO }>) => {
        state.list = state.list.map((item) => (item.id !== action.payload.item.id ? item : action.payload.item));
      },
    },

    // --------

    deleteFromList: {
      prepare: (id: number) => ({
        payload: {
          id,
        },
      }),

      reducer: (state, action: PayloadAction<{ id: number }>) => {
        state.list = state.list.filter((item) => item.id !== action.payload.id);
      },
    },
  },

  // -------- Extra reducers --------

  extraReducers: (b) => {
    b.addCase(globalAC.setDefaultState, (state) => {
      return initialState;
    });
  },
});

export { initialState, transmissionSaga };
export type { ITransmissionBranch };
export default { ...transmissionSlice, actions: { ...transmissionSlice.actions, ...AC } };
