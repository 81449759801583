import { FiberDTO, FiberDO } from './';
import { createKeysMappers } from '../base/utils/createKeysMappers';

// ----------------

export const fiberMapper = {
  toDO(data: FiberDTO): FiberDO {
    // prettier-ignore
    return {
      fiberDeliveredEstimate: data.fiber_delivered_estimate,
      ringSendToEricsson    : data.ring_send_to_ericsson,
      fiberWorksFinished    : data.fiber_works_finished,
      publicPermitRegion    : data.public_permit_region,
      civilWorksFinished    : data.civil_works_finished,
      publicPermitCity      : data.public_permit_city,
      deliveryForecast      : data.delivery_forecast,
      fiberWorksStart       : data.fiber_works_start,
      civilWorksStart       : data.civil_works_start,
      insertedInRing        : data.inserted_in_ring,
      fiberDelivered        : data.fiber_delivered,
      builtReceived         : data.as_built_received,
      atn5gSFPReady         : data.atn_5g_sfp_ready || '',
      splicePartner         : data.splice_partner,
      subcontractor         : data.subcontractor,
      builtSplice           : data.as_built_splice,
      ringAtnName           : data.ring_atn_name,
      fiberDesign           : data.fiber_design,
      orderDate             : data.order_date,
      ownerAuth             : data.owner_auth,
      comments              : data.comments,
      safFile               : data.saf_file,
      network               : data.network,
      coccon                : data.coccon,
      pmObe                 : data.pm_obe,
      txID                  : data.transmission,
      ring                  : data.ring,
      popa                  : data.popa,
      popb                  : data.popb,
      fac                   : data.fac,
      ssv                   : data.ssv,
      rfb                   : data.rfb,
      id                    : data.id
    };
  },

  // --------

  toPartialDTO(data: Partial<FiberDO>): Partial<FiberDTO> {
    // prettier-ignore
    return {
      fiber_delivered_estimate: data.fiberDeliveredEstimate,
      ring_send_to_ericsson   : data.ringSendToEricsson,
      fiber_works_finished    : data.fiberWorksFinished,
      public_permit_region    : data.publicPermitRegion,
      civil_works_finished    : data.civilWorksFinished,
      public_permit_city      : data.publicPermitCity,
      delivery_forecast       : data.deliveryForecast,
      fiber_works_start       : data.fiberWorksStart,
      as_built_received       : data.builtReceived,
      civil_works_start       : data.civilWorksStart,
      inserted_in_ring        : data.insertedInRing,
      as_built_splice         : data.builtSplice,
      fiber_delivered         : data.fiberDelivered,
      splice_partner          : data.splicePartner,
      ring_atn_name           : data.ringAtnName,
      subcontractor           : data.subcontractor,
      transmission            : data.txID,
      fiber_design            : data.fiberDelivered,
      order_date              : data.orderDate,
      owner_auth              : data.ownerAuth,
      comments                : data.comments,
      saf_file                : data.safFile,
      network                 : data.network,
      coccon                  : data.coccon,
      pm_obe                  : data.pmObe,
      ring                    : data.ring,
      popa                    : data.popa,
      popb                    : data.popb,
      fac                     : data.fac,
      ssv                     : data.ssv,
      rfb                     : data.rfb,
    };
  },

  // --------

  // prettier-ignore
  ...createKeysMappers<FiberDTO, FiberDO>([
    ['fiber_delivered_estimate', 'fiberDeliveredEstimate'],
    ['ring_send_to_ericsson'   , 'ringSendToEricsson'],
    ['fiber_works_finished'    , 'fiberWorksFinished'],
    ['public_permit_region'    , 'publicPermitRegion'],
    ['civil_works_finished'    , 'civilWorksFinished'],
    ['public_permit_city'      , 'publicPermitCity'],
    ['delivery_forecast'       , 'deliveryForecast'],
    ['fiber_works_start'       , 'fiberWorksStart'],
    ['as_built_received'       , 'builtReceived'],
    ['civil_works_start'       , 'civilWorksStart'],
    ['atn_5g_sfp_ready'        , 'atn5gSFPReady'],
    ['inserted_in_ring'        , 'insertedInRing'],
    ['as_built_splice'         , 'builtSplice'],
    ['fiber_delivered'         , 'fiberDelivered'],
    ['splice_partner'          , 'splicePartner'],
    ['ring_atn_name'           , 'ringAtnName'],
    ['subcontractor'           , 'subcontractor'],
    ['fiber_design'            , 'fiberDesign'],
    ['order_date'              , 'orderDate'],
    ['owner_auth'              , 'ownerAuth'],
    ['comments'                , 'comments'],
    ['saf_file'                , 'safFile'],
    ['network'                 , 'network'],
    ['coccon'                  , 'coccon'],
    ['pm_obe'                  , 'pmObe'],
    ['ring'                    , 'ring'],
    ['popa'                    , 'popa'],
    ['popb'                    , 'popb'],
    ['fac'                     , 'fac'],
    ['ssv'                     , 'ssv'],
    ['rfb'                     , 'rfb'],
  ]),
};
