import { middlewareData, topLevelRoutes } from '../../../';
import jwtDecode from 'jwt-decode';

// Config
import { localStorageKeys, redirectRoutes, globalObj } from '../../../../config/app';

// Utils
import hasPermissions from '../../../../utils/hasPermissions/hasPermissions';

// Types
import { Router as Router5, State } from 'router5';
import { Router } from '../../../../types';

// Define the expected structure of the decoded JWT
interface DecodedToken {
  exp: number; // Assuming exp is a Unix timestamp in seconds
  // Add other fields that you expect in the token
}

/**
 * Checks if the user has access to a specific route.
 *
 * @param access    - Access config object
 * @param router    - router5 instance
 * @param toState   - Next state of router
 * @param fromState - Previous state of router
 * @param done      - This function can allow or deny access to the route
 */
export default function accessActivator(access: Router.IRouteAccess, router: Router5, toState: State, fromState: State, done: Router.DoneFN): void {
  (async () => {
    let token = localStorage.getItem(localStorageKeys.token);
    let tokenIsValid = false;
    let mfaValidated = localStorage.getItem(localStorageKeys.otpValidated) === 'true';


    if (token) {
      try {
        const jwt: DecodedToken = jwtDecode(token);
        tokenIsValid = new Date(jwt.exp * 1000) > new Date(); // Correctly validate token expiration
        if (!tokenIsValid) {
          console.log('accessActivator - Token expired');
        }
      } catch (err) {
        console.log('accessActivator - Not valid token');
      }
    }
    if (!tokenIsValid) {
      if (toState.name !== 'auth.login') {
        console.log("Redirecting to login because the token is invalid");
        done({ redirect: { name: redirectRoutes.defaultForNonAuth } });
        return;
      }
    } else if (tokenIsValid && !mfaValidated) {
      if (toState.name !== 'auth.mfa') {
        console.log("Redirecting to MFA because MFA is not validated");
        done({ redirect: { name: 'auth.mfa' } });
        return;
      }
    } else if (tokenIsValid && mfaValidated) {
      if (toState.name === 'auth.login' || toState.name === 'auth.mfa') {
        console.log("Redirecting to dashboard because token and MFA are valid");
        done({ redirect: { name: 'main.dashboard' } });
        return;
      }
    }

    if (access.permissions || access.test) {
      let hasAccess = true;
      if (access.permissions) {
        hasAccess = hasPermissions(access.permissions);
      }
      if (hasAccess && access.test) {
        hasAccess = access.test(globalObj.reduxStore!, router, toState, fromState);
      }
      if (!hasAccess) {
        let availableRoute = access.forwardTo && access.forwardTo.length
          ? access.forwardTo.find(route => hasPermissions(middlewareData[route].access?.permissions))
          : topLevelRoutes.find(route => hasPermissions(middlewareData[route].access?.permissions));


        if (availableRoute) {
          done({ redirect: { name: availableRoute, params: toState.params } });
        } else {
          done({ redirect: { name: redirectRoutes.defaultForNonAccess } });
        }
        return;
      }
    }

    if (access.beforeEnter) {
      await new Promise((res) => access.beforeEnter!(globalObj.reduxStore!, router, toState, fromState, done, res));
    }

    done(); // Complete the navigation if all checks pass
  })();
}