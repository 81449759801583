
// Utils

import { createKeysMappers } from '../base/utils/createKeysMappers';
import { SmallWorksDO } from './SmallWorksDO';
import { SmallWorksDTO } from './SmallWorksDTO';

// ----------------

export const smallWorksMapper = {
  toDO(data: SmallWorksDTO.$): SmallWorksDO.$ {
    // prettier-ignore
    return {
      swNumber          : data.sw_number,
      subcontractor     : data.subcontractor,
      description       : data.description,
      startDatePlanned  : data.start_date_planned,
      permits           : data.permits,
      startDateActual   : data.start_date_actual,
      finalCompletionDate: data.final_completion_date,
      fssr              : data.fssr,
      checkAsBuilt      : data.check_as_built,
      boqInitial        : data.boq_initial,
      boqFinal          : data.boq_final,
      ber               : data.ber,
      bep               : data.bep,
      comments          : data.comments,
      coconEncoding    : data.cocon_encoding,
      swOwner           : data.sw_owner,
      commentAsBuilt    : data.comment_as_built,
      isArchived        : data.is_archived,
      id                : data.id
    };
  },

  // --------

  toDTO(data: Partial<SmallWorksDO.$>): Partial<SmallWorksDTO.$> {
    // prettier-ignore
    return {
      sw_number          : data.swNumber,
      subcontractor      : data.subcontractor,
      description        : data.description,
      start_date_planned : data.startDatePlanned,
      permits            : data.permits,
      start_date_actual  : data.startDateActual,
      final_completion_date: data.finalCompletionDate,
      fssr               : data.fssr,
      check_as_built     : data.checkAsBuilt,
      boq_initial        : data.boqInitial,
      boq_final          : data.boqFinal,
      ber                : data.ber,
      bep                : data.bep,
      comments           : data.comments,
      cocon_encoding    : data.coconEncoding,
      sw_owner           : data.swOwner,
      comment_as_built   : data.commentAsBuilt,
      is_archived        : data.isArchived,
      id                 : data.id
    };
  },

  // --------

  ...createKeysMappers<SmallWorksDTO.$, SmallWorksDO.$>([
    ['sw_number', 'swNumber'],
    ['subcontractor', 'subcontractor', { type: 'glossary', glossaryType: 'smallWorksSubcontractor' }],
    ['description', 'description'],
    ['start_date_planned', 'startDatePlanned', { type: 'date' }],
    ['permits', 'permits'],
    ['start_date_actual', 'startDateActual', { type: 'date' }],
    ['final_completion_date', 'finalCompletionDate', { type: 'date' }],
    ['fssr', 'fssr'],
    ['check_as_built', 'checkAsBuilt', { type: 'glossary', glossaryType: 'smallWorksCheckAsBuilt' }],
    ['boq_initial', 'boqInitial'],
    ['boq_final', 'boqFinal'],
    ['ber', 'ber'],
    ['bep', 'bep'],
    ['comments', 'comments'],
    ['cocon_encoding', 'coconEncoding'],
    ['sw_owner', 'swOwner'],
    ['comment_as_built', 'commentAsBuilt'],
    ['is_archived', 'isArchived'],
    ['id', 'id']
  ]),
};
