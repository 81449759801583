import { createAction } from '@reduxjs/toolkit';

// Config

import { reduxActionTypePrefix } from '../../../config/app';

// Entity

import { TransmissionListQueryDTO, TransmissionDTOPartial, TransmissionFiberDTOPartial } from '../../../entity/transmission/DTO';
import { ITransmissionDO, TransmissionFiberDO } from '../../../entity/transmission/DO';
import { FiberDTO, FiberDO } from '../../../entity/fiber';

// ----------------

const cat = (actionPiece: string) => `${reduxActionTypePrefix}/transmission/${actionPiece}`; // Create action type

export default {
  getList$Req: createAction(
    cat('getList$Req'),
    (props: { onSuccess?: (data: ITransmissionDO[]) => void; onError?: () => void; message?: string; query: TransmissionListQueryDTO }) => ({
      payload: {
        query: props.query,
      },
      meta: {
        onSuccess: props.onSuccess,
        onError: props.onError,
        message: props.message,
      },
    })
  ),

  getClearList$Req: createAction(
    cat('getClearList$Req'),
    (props: { onSuccess?: (data: ITransmissionDO[]) => void; onError?: () => void; message?: string; query: TransmissionListQueryDTO }) => ({
      payload: {
        query: props.query,
      },
      meta: {
        onSuccess: props.onSuccess,
        onError: props.onError,
        message: props.message,
      },
    })
  ),

  getById$Req: createAction(cat('getById$Req'), (props: { id: number; onSuccess?: (data: ITransmissionDO) => void }) => ({
    payload: {
      id: props.id,
    },
    meta: {
      onSuccess: props.onSuccess,
    },
  })),

  add$Req: createAction(
    cat('add$Req'),
    (props: { data: TransmissionDTOPartial; onSuccess?: (data: ITransmissionDO) => void; onError?: (data: any) => void; message?: string }) => ({
      payload: {
        data: props.data,
      },
      meta: {
        onSuccess: props.onSuccess,
        onError: props.onError,
        message: props.message,
      },
    })
  ),

  update$Req: createAction(
    cat('update$Req'),
    (props: {
      onSuccess?: (data: ITransmissionDO) => void;
      onError?: (data: any) => void;
      message?: string;
      data: TransmissionDTOPartial;
      id: number;
    }) => ({
      payload: {
        data: props.data,
        id: props.id,
      },
      meta: {
        onSuccess: props.onSuccess,
        onError: props.onError,
        message: props.message,
      },
    })
  ),

  delete$Req: createAction(cat('delete$Req'), (props: { id: number; onSuccess?: () => void; message?: string }) => ({
    payload: {
      id: props.id,
    },
    meta: {
      onSuccess: props.onSuccess,
      message: props.message,
    },
  })),

  import$Req: createAction(cat('import$Req'), (props: { file: File; onSuccess?: () => void; onError?: (data: any) => void; message?: string }) => ({
    payload: {
      file: props.file,
    },
    meta: {
      onSuccess: props.onSuccess,
      onError: props.onError,
      message: props.message,
    },
  })),

  export$Req: createAction(
    cat('export$Req'),
    (props: { data: TransmissionListQueryDTO; onSuccess?: () => void; onError?: () => void; message?: string }) => ({
      payload: {
        data: props.data,
      },
      meta: {
        onSuccess: props.onSuccess,
        onError: props.onError,
        message: props.message,
      },
    })
  ),

  cancelMove$Req: createAction(cat('cancelMove$Req'), (props: { id: number; onSuccess?: () => void; onError?: () => void; message?: string }) => ({
    payload: {
      id: props.id,
    },
    meta: {
      onSuccess: props.onSuccess,
      onError: props.onError,
      message: props.message,
    },
  })),

  getFiberById$Req: createAction(cat('getFiberById$Req'), (props: { id: number; onSuccess?: (data: TransmissionFiberDO) => void }) => ({
    payload: {
      id: props.id,
    },
    meta: {
      onSuccess: props.onSuccess,
    },
  })),


  addFiber$Req: createAction(
    cat('addFiber$Req'),
    (props: { onSuccess?: (data: FiberDO) => void; onError?: (data: any) => void; message?: string; data: Partial<FiberDTO> }) => ({
      payload: {
        data: props.data,
      },
      meta: {
        onSuccess: props.onSuccess,
        onError: props.onError,
        message: props.message,
      },
    })
  ),

  updateFiber$Req: createAction(
    cat('updateFiber$Req'),
    (props: {
      transmissionFiberId: number;
      onSuccess?: (data: FiberDO) => void;
      onError?: (data: any) => void;
      message?: string;
      data: Partial<FiberDTO>;
    }) => ({
      payload: {
        transmissionFiberId: props.transmissionFiberId,
        data: props.data,
      },
      meta: {
        onSuccess: props.onSuccess,
        onError: props.onError,
        message: props.message,
      },
    })
  ),

  dismantle$Req: createAction(
    cat('dismantle$Req'),
    (props: { id: number; onSuccess?: (data: ITransmissionDO) => void; onError?: (data: any) => void; message?: string }) => ({
      payload: {
        id: props.id,
      },
      meta: {
        onSuccess: props.onSuccess,
        onError: props.onError,
        message: props.message,
      },
    })
  ),
};
