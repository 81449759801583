import { BaseDTO, GlossaryId, Number, Date } from '../base';

// ----------------

export namespace SmallWorksDTO {
  // prettier-ignore
  export type $ = {
    comment_as_built: string;
    sw_number: string;
    subcontractor: GlossaryId;
    description: string;
    start_date_planned: Date;
    permits: string;
    start_date_actual: Date;
    final_completion_date: Date;
    fssr: string;
    check_as_built: GlossaryId;
    boq_initial: string;
    boq_final: string;
    ber: string;
    bep: string;
    comments: string;
    cocon_encoding: boolean;
    sw_owner: string;
    is_archived: boolean;
    id: number;
  };

  export type PaginatedList = BaseDTO.PaginatedList<$>;
}
