import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import { RootState } from './redux';
import { Router } from './router';
import React from 'react';

// Config

import config from './config';

// Components

import { LocalizationProvider } from '@mui/x-date-pickers';
import I18nProvider from './components/contextProviders/i18n/i18nProvider';
import LoadingBar from 'react-top-loading-bar';
import Toast from './components/view/common/Toast';

// Styles

import { createTheme, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themeConfig from './styles/themeConfig';

// ----------------

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// ----------------

const App: React.FC = () => {
  const paletteMode = useSelector((state: RootState) => state.app.palette);

  /**
   * Creating final theme.
   * Theme may update when some data change.
   */

  const theme = React.useMemo(() => {
    return createTheme({
      breakpoints: themeConfig.breakpoints,
      typography: themeConfig.typography,
      components: themeConfig.components,
      palette: themeConfig.palette[paletteMode],
      spacing: themeConfig.spacing,
    });
  }, [paletteMode]);

  return (
    <I18nProvider>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Router />
          </LocalizationProvider>

          <LoadingBar
            ref={(ref) => {
              config.app.globalObj.loadingBar = ref;
            }}
            loaderSpeed={400}
            waitingTime={400}
            color="#ff8c29"
          />
          <Toast />
        </ThemeProvider>
      </StyledEngineProvider>
    </I18nProvider>
  );
};

export default App;
