import { DateDOValue, GlossaryDOValue } from '../common/DO';
import { MicrowaveDO } from '../microwave';

// ----------------

// prettier-ignore
export enum TransmissionDOKeys {
    familyMpProjected = 'familyMpProjected',
    consoYearMwingz = 'consoYearMwingz',
    radioSiteType   = 'radioSiteType',
    clusterMwingz   = 'clusterMwingz',
    microwaveData   = 'microwaveData',
    currentTechno   = 'currentTechno',
    doubleStream    = 'doubleStream',
    targetTechno    = 'targetTechno',
    radioStatus     = 'radioStatus',
    familyGroup     = 'familyGroup',
    leasedLine      = 'leasedLine',
    mwingzCode      = 'mwingzCode',
    tempTechno      = 'tempTechno',
    isArchived      = 'isArchived',
    familyTemp      = 'familyTemp',
    updateDate      = 'updateDate',
    originals       = 'originals',
    microwave       = 'microwave',
    fiberData       = 'fiberData',
    longitude       = 'longitude',
    latitude        = 'latitude',
    bmStatus        = 'bmStatus',
    isDraft         = 'isDraft',
    address         = 'address',
    siteId          = 'siteId',
    region          = 'region',
    status          = 'status',
    target          = 'target',
    family          = 'family',
    city            = 'city',
    zip             = 'zip',
    id              = 'id',

    candidate2g = 'candidate2g',
    candidate3g = 'candidate3g',
    candidate4g = 'candidate4g',
    candidate5g = 'candidate5g',

    site2g = 'site2g',
    site3g = 'site3g',
    site4g = 'site4g',
    site5g = 'site5g',

    familyBmProjected = 'familyBmProjected',
    familyBmLegacy    = 'familyBmLegacy',
  }

// prettier-ignore
export interface ITransmissionDO {
  [TransmissionDOKeys.familyMpProjected]: number;
  [TransmissionDOKeys.consoYearMwingz]: number | null;
  [TransmissionDOKeys.radioSiteType]  : number | null;
  [TransmissionDOKeys.clusterMwingz]  : number | null;
  [TransmissionDOKeys.currentTechno]  : number | null;
  [TransmissionDOKeys.microwaveData]  : MicrowaveDO.MicrowaveDO | null;
  [TransmissionDOKeys.doubleStream]   : number | null;
  [TransmissionDOKeys.targetTechno]   : number | null;
  [TransmissionDOKeys.radioStatus]    : number | null;
  [TransmissionDOKeys.isArchived]     : boolean;
  [TransmissionDOKeys.mwingzCode]     : string;
  [TransmissionDOKeys.tempTechno]     : number | null;
  [TransmissionDOKeys.familyGroup]    : FamilyGroup;
  [TransmissionDOKeys.leasedLine]     : null;
  [TransmissionDOKeys.updateDate]     : string | null;
  [TransmissionDOKeys.familyTemp]     : number | null;
  [TransmissionDOKeys.microwave]      : number | null;
  [TransmissionDOKeys.originals]      : number[];
  [TransmissionDOKeys.longitude]      : number
  [TransmissionDOKeys.fiberData]      : TransmissionFiberDO | null;
  [TransmissionDOKeys.latitude]       : number,
  [TransmissionDOKeys.bmStatus]       : number | null;
  [TransmissionDOKeys.isDraft]        : boolean;
  [TransmissionDOKeys.address]        : string;
  [TransmissionDOKeys.siteId]         : string
  [TransmissionDOKeys.region]         : string
  [TransmissionDOKeys.status]         : number | null;
  [TransmissionDOKeys.target]         : number | null;
  [TransmissionDOKeys.family]         : number | null;
  [TransmissionDOKeys.city]           : number | null;
  [TransmissionDOKeys.zip]            : number;
  [TransmissionDOKeys.id]             : number;

  [TransmissionDOKeys.candidate2g]: string;
  [TransmissionDOKeys.candidate3g]: string;
  [TransmissionDOKeys.candidate4g]: string;
  [TransmissionDOKeys.candidate5g]: string;

  [TransmissionDOKeys.site2g]: string;
  [TransmissionDOKeys.site3g]: string;
  [TransmissionDOKeys.site4g]: string;
  [TransmissionDOKeys.site5g]: string;
}

export type TransmissionDOPartial = Partial<ITransmissionDO>;

// prettier-ignore
export enum TransmissionFiberDOKeys {
  fiberDeliveredEstimated = 'fiberDeliveredEstimated',
  ownerAuthorization = 'ownerAuthorization',
  publicPermitRegion = 'publicPermitRegion',
  civilWorksFinish   = 'civilWorksFinish',
  fiberWorksFinish   = 'fiberWorksFinish',
  deliveryForecast   = 'deliveryForecast',
  ringSentEricsson   = 'ringSentEricsson',
  publicPermitCity   = 'publicPermitCity',
  civilWorksStart    = 'civilWorksStart',
  splicingPartner    = 'splicingPartner',
  fiberWorksStart    = 'fiberWorksStart',
  fiberDelivered     = 'fiberDelivered',
  builtReceived      = 'builtReceived',
  subcontractor      = 'subcontractor',
  cancellation       = 'cancellation',
  insertedRING       = 'insertedRING',
  transmission       = 'transmission',
  fiberDesign        = 'fiberDesign',
  builtSplice        = 'builtSplice',
  SSVComments        = 'SSVComments',
  ATNRingName        = 'ATNRingName',
  orderDate          = 'orderDate',
  ringName           = 'ringName',
  network            = 'network',
  PMOBE              = 'PMOBE',
  cocon              = 'cocon',
  SAF                = 'SAF',
  RFB                = 'RFB',
  SSV                = 'SSV',
  FAC                = 'FAC',
}

// prettier-ignore
export type TransmissionFiberDO = {
  [TransmissionFiberDOKeys.fiberDeliveredEstimated]: DateDOValue,
  [TransmissionFiberDOKeys.ownerAuthorization]     : DateDOValue,
  [TransmissionFiberDOKeys.publicPermitRegion]     : DateDOValue,
  [TransmissionFiberDOKeys.civilWorksFinish]       : DateDOValue,
  [TransmissionFiberDOKeys.fiberWorksFinish]       : DateDOValue,
  [TransmissionFiberDOKeys.publicPermitCity]       : DateDOValue,
  [TransmissionFiberDOKeys.ringSentEricsson]       : DateDOValue,
  [TransmissionFiberDOKeys.deliveryForecast]       : DateDOValue,
  [TransmissionFiberDOKeys.civilWorksStart]        : DateDOValue,
  [TransmissionFiberDOKeys.splicingPartner]        : GlossaryDOValue,
  [TransmissionFiberDOKeys.fiberWorksStart]        : DateDOValue,
  [TransmissionFiberDOKeys.fiberDelivered]         : DateDOValue,
  [TransmissionFiberDOKeys.builtReceived]          : DateDOValue,
  [TransmissionFiberDOKeys.subcontractor]          : GlossaryDOValue,
  [TransmissionFiberDOKeys.cancellation]           : DateDOValue,
  [TransmissionFiberDOKeys.insertedRING]           : DateDOValue,
  [TransmissionFiberDOKeys.transmission]           : number,
  [TransmissionFiberDOKeys.fiberDesign]            : DateDOValue,
  [TransmissionFiberDOKeys.builtSplice]            : DateDOValue,
  [TransmissionFiberDOKeys.SSVComments]            : string,
  [TransmissionFiberDOKeys.ATNRingName]            : GlossaryDOValue,
  [TransmissionFiberDOKeys.orderDate]              : DateDOValue,
  [TransmissionFiberDOKeys.ringName]               : GlossaryDOValue,
  [TransmissionFiberDOKeys.network]                : GlossaryDOValue,
  [TransmissionFiberDOKeys.PMOBE]                  : GlossaryDOValue,
  [TransmissionFiberDOKeys.cocon]                  : boolean,
  [TransmissionFiberDOKeys.SAF]                    : DateDOValue,
  [TransmissionFiberDOKeys.RFB]                    : DateDOValue,
  [TransmissionFiberDOKeys.SSV]                    : DateDOValue,
  [TransmissionFiberDOKeys.FAC]                    : DateDOValue,
};

export type TransmissionFiberDOPartial = Partial<TransmissionFiberDO>;

// --------

export type FamilyGroup = 'fiber' | 'leasedLine' | 'microwave' | null;
